export const AvailableApps = {
  NsBi: "NSBI",
  LiveDispatch: "Live Dispatch",
  TattleSystems: "Tattle Systems",
};

export const RoleIds = {
  Admin: 1,
  Manager: 2,
  User: 3,
  TattleSystems: 4,
  LiveDispatch: 5,
  WhiteLabelPartnership: 6,
};

export const ElementStyle = {
  Default: "default",
  Primary: "primary",
  Secondary: "secondary",
  Tertiary: "tertiary",
  Quaternary: "quaternary",
  Success: "success",
  Info: "info",
  Warning: "warning",
  Danger: "danger",
  Dark: "dark",
};

export const ElementSize = {
  xxl: "xxl",
  xl: "xl",
  lg: "lg",
  md: "md",
  sm: "sm",
  xs: "xs",
};

export const CustomStatusCodes = {
  ThirdPartyBadRequest: 4000400,
  ThirdPartyUnauthorized: 4000401,
  ThirdPartyForbidden: 4000403,
  ThirdPartyInternalServerError: 5000500,
};

export const LoadingIconTypes = {
  // https://loading.io/
  Spinner: process.env.PUBLIC_URL + "/assets/img/spinners/spinner.gif",
  Pulse: process.env.PUBLIC_URL + "/assets/img/spinners/pulse.gif",
  Wedges: process.env.PUBLIC_URL + "/assets/img/spinners/wedges.gif",
  Ripple: process.env.PUBLIC_URL + "/assets/img/spinners/ripple.gif",
};

export const ReportTypes = {
  Dashboard: "dashboard",
  ExpressView: "expressview",
  Advanced: "advanced",
  CrossTab: "crosstab",
  Chained: "chained",
};

export const FullScreenCss =
  "@media only screen and (min-width: 768px) { html.fixed .content-body { padding: 0; } }";

export const MetaDataKeys = {
  PrimaryPhoneNumber: "primary_phone_number",
  SecondaryPhoneNumber: "secondary_phone_number",
  Notes: "notes",
  AdminNotes: "admin_notes",
  CompanyName: "company_name",
  Settings_TwoFactorEnabled: "settings.two_factor_enabled",
  Settings_SingleSessionLimit: "settings.single_session_limit",
  Settings_NsbiEnabled: "settings.nsbi_enabled",
};

export const SortDirections = {
  Ascending: "asc",
  Descending: "desc",
};

export const NodePositions = {
  Prepend: "prepend",
  Append: "append",
};

export const SystemSettings = {
  MaintenanceMode: "maintenance_mode",
};
