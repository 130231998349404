import React, { useContext, useState } from "react";
import { AvailableApps, MetaDataKeys } from "../constants/misc";
import {
  AppContext,
  ModalsContext,
  UserContext,
} from "../context/ContextProvider";

import { Link } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import PageHeaderV2 from "../components/PageHeaderV2";
import StyledCard from "../components/styled/StyledCard";
import { Routes } from "../constants/routes";
import { StorageProps } from "../constants/storageProps";
import apiService from "../services/api/apiServices";
import appServices from "../services/appServices";

const DashboardPage = (props) => {
  const { systemSettings } = useContext(AppContext);
  const modalsContext = useContext(ModalsContext);
  const userContext = useContext(UserContext);

  const maintenanceMode = systemSettings?.find(
    (x) => x.key === "maintenance_mode"
  )?.value;

  const isLDLinked =
    userContext.currentUser && userContext.currentUser.ld?.user_id
      ? true
      : false;

  const [isTSLinked, setIsTSLinked] = useState(
    userContext.currentUser && userContext.currentUser.ts?.user_id
      ? true
      : false
  );
  const userReportsEnabled = !!Number(
    appServices.user.getMetaData(
      userContext.currentUser,
      MetaDataKeys.Settings_NsbiEnabled
    )
  );

  const handleAppClick = async (app) => {
    let appUrl = null;

    switch (app) {
      case AvailableApps.TattleSystems:
        const isTsSessionValid = await tryRefreshTattleUserAsync();
        if (!isTsSessionValid) {
          // show the login form again. the user logged in from another device or logged out
          modalsContext.setShowTSLoginModal(true);
          return;
        }
        appUrl = `${process.env.REACT_APP_TS_URL}/${process.env.REACT_APP_TS_JSESSIONID_PARAMETER}?${userContext.currentUser?.ts?.jsession_id}`;
        break;

      case AvailableApps.LiveDispatch:
        appUrl = `${process.env.REACT_APP_LD_URL}?access_token=${userContext.currentUser?.ld?.access_token}`;
        break;

      case AvailableApps.NsBi:
        return await appServices.api.userLogs.increaseNSBIClicks();

      default:
        return;
    }

    // console.log(appUrl);
    window.open(appUrl);
  };

  // eslint-disable-next-line
  async function tryRefreshTattleUserAsync() {
    //no-unused-vars
    try {
      if (isTSLinked) {
        await apiService.auth.getTattleUser();
        setIsTSLinked(true);
      }
    } catch (ex) {
      setIsTSLinked(false);
      userContext.onCurrentUserChanged(
        appServices.sessionStorage.getProperty(StorageProps.currentUser)
      );
      return false;
    }

    return true;
  }

console.log("LD linked? = ", isLDLinked);
  return (
    <>
      <PageHeaderV2 title="Applications">
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs"></ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        {/* NS BI */}
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-3">
          <StyledCard
            className={`dashboard-card ${
              !maintenanceMode && !!userReportsEnabled
                ? ""
                : "dashboard-card-disabled"
            }`}
            cardBodyStyle={{ height: "100%" }}
          >
            <div className="dashboard-card-body">
              <Link
                to={Routes.reports}
                className="dashboard-card-overlay"
                onClick={() => handleAppClick(AvailableApps.NsBi)}
                title="NS BI"
              >
                <img
                  className="external-app-icon"
                  src={`${process.env.PUBLIC_URL}/assets/img/bi-logo.png`}
                  height="150"
                  alt="NS BI"
                />
              </Link>
              {!maintenanceMode || (
                <div
                  className="dashboard-card-overlay center"
                  style={{
                    // backgroundColor: "#dddddd",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="/#"
                    className="btn btn-default"
                    // onClick={() => {
                    //   modalsContext.setShowTSLoginModal(true);
                    // }}
                    style={{
                      margin: "0 auto",
                      color: "#000000",
                      pointerEvents: "all",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/work-in-progress.svg`}
                      style={{ maxWidth: 20, margin: 10, float: "left" }}
                      // height="35"
                      alt="Portal"
                    />
                    Sorry, we're down for scheduled maintenance.
                  </a>
                </div>
              )}
            </div>
          </StyledCard>
        </div>
        {/* Tattle Systems */}
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-3">
          <StyledCard
            className={`dashboard-card ${
              isTSLinked ? "" : "dashboard-card-disabled"
            }`}
            cardBodyStyle={{ height: "100%" }}
          >
            <div className="dashboard-card-body">
              <img
                className="external-app-icon dashboard-card-overlay cur-pointer"
                src={`${process.env.PUBLIC_URL}/assets/img/tattle-systems-logo.png`}
                onClick={() => handleAppClick(AvailableApps.TattleSystems)}
                height="150"
                alt="Tattle Systems"
              />

              {isTSLinked || (
                <div
                  className="dashboard-card-overlay center"
                  style={{
                    // backgroundColor: "#dddddd",
                    height: "150px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="/#"
                    className="btn btn-default"
                    onClick={() => {
                      modalsContext.setShowTSLoginModal(true);
                    }}
                    style={{
                      margin: "0 auto",
                      color: "#000000",
                      pointerEvents: "all",
                    }}
                  >
                    App unlinked (your session may be expired).
                    <br />
                    Click here to link Tattle Systems.
                  </a>
                </div>
              )}
            </div>
          </StyledCard>
        </div>
        {/* Live Dispatch */}
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-3">
          <StyledCard
            className={`dashboard-card ${
              isLDLinked ? "" : "dashboard-card-disabled"
            }`}
            cardBodyStyle={{ height: "100%" }}
          >
            <div className="dashboard-card-body">
              <img
                className="external-app-icon dashboard-card-overlay cur-pointer"
                src={`${process.env.PUBLIC_URL}/assets/img/live-dispatch-logo.png`}
                onClick={() => handleAppClick(AvailableApps.LiveDispatch)}
                height="150"
                alt="Live Dispatch"
              />

              {isLDLinked || (
                <div
                  className="dashboard-card-overlay center"
                  style={{
                    // backgroundColor: "#dddddd",
                    height: "150px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="/#"
                    className="btn btn-default"
                    onClick={() => {
                      modalsContext.setShowLDLoginModal(true);
                    }}
                    style={{
                      margin: "0 auto",
                      color: "#000000",
                      pointerEvents: "all",
                    }}
                  >
                    App unlinked (your session may be expired).
                    <br />
                    Click here to link Live Dispatch.
                  </a>
                </div>
              )}
            </div>
          </StyledCard>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
