import authApiService from "./authApiService";
import dundasApiService from "./dundasApiService";
import filesApiService from "./filesApiService";
import ldApiService from "./liveDispatch/ldApiService";
import metaDataApiService from "./metaDataApiService";
import passwordsApiService from "./passwordsApiService";
import rolesApiService from "./rolesApiService";
import systemSettingsApiService from "./systemSettingsApiService";
import tsApiService from "./tattleSystems/tsApiService";
import twoFactorApiService from "./twoFactorApiService";
import userLogsApiService from "./userLogsApiService";
import usersApiService from "./usersApiService";

//------------------------------------------------
const apiService = {
  auth: authApiService,
  dundas: dundasApiService,
  files: filesApiService,
  metaData: metaDataApiService,
  passwords: passwordsApiService,
  roles: rolesApiService,
  systemSettings: systemSettingsApiService,
  twoFA: twoFactorApiService,
  userLogs: userLogsApiService,
  users: usersApiService,

  liveDispatch: ldApiService,
  tattleSystems: tsApiService,
};

export default apiService;
//------------------------------------------------
