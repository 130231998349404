// import * as Yup from "yup";
const Yup = require("yup");

const fullNameSchema = Yup.string().required().label("Full name");
const passwordSchema = Yup.string()
  .min(6, "Password is too short (min 6)")
  .max(15, "Password is too long (max 15)")
  .label("Password");
const phoneNumberSchema = Yup.string()
  .min(10, "Phone number must be at least 10 digits")
  .optional()
  .matches(/^[0-9]{7,10}$/, "Enter a valid phone number (only numbers)")
  .label("Phone number");

const createUser = Yup.object().shape({
  name: fullNameSchema,
  email: Yup.string().email().min(1).required().label("Email"),
  password: passwordSchema.required(),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .label("Confirm password"),
  primary_phone_number: phoneNumberSchema,
  secondary_phone_number: phoneNumberSchema,
});

const editUser = Yup.object().shape({
  name: fullNameSchema,
  email: Yup.string().email().min(1).required().label("Email"),
  password: passwordSchema,
  password_confirmation: Yup.string()
    .when("password", {
      is: (password) => password?.length > 0,
      then: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: Yup.string()
        .optional()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    })
    .label("Confirm password"),
  primary_phone_number: phoneNumberSchema,
  secondary_phone_number: phoneNumberSchema,
});

// Check out this issue: https://github.com/sideway/Yup/issues/2702

//------------------------------------------------
const schemas = {
  createUser,
  editUser,
};
export default schemas;
//------------------------------------------------
