import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

/**
 * Returns all the users in the database
 * @returns Http response
 */
const all = async (urlParams = "") => {
  let url = ApiRoutes.usersAll;
  if (urlParams) {
    url = `${url}${urlParams}`;
  }

  const response = await httpService.get(url);
  // console.log("All Users", response);

  return response;
};

/**
 * Returns the user by id
 * @returns Http response
 */
const get = async (id) => {
  const url = ApiRoutes.usersGet.replace("{id}", id);

  const response = await httpService.get(url);
  // console.log("User", response);

  return response;
};

/**
 *
 * @param {object} payload
 * @returns
 */
const create = async (payload) => {
  payload = {
    ...payload,
    role_id: parseInt(payload?.role_id),
  };
  const response = await httpService.post(ApiRoutes.usersCreate, payload);

  return response;
};

/**
 * Deletes a user
 * @param {int} id The user id
 * @param {bool} force TRUE=hard-delete, FALSE=soft-delete
 * @returns
 */
const destroy = async (id, force = null) => {
  let url =
    `${process.env.REACT_APP_API_BASE_URL}/${ApiRoutes.usersDelete}`.replace(
      "{id}",
      id
    );
  if (force) url = `${url}?force=${force}`;

  const response = await httpService.delete(url, true);

  return response;
};

/**
 *
 * @param {int} id User id
 * @returns
 */
const getReports = async (id) => {
  const url = ApiRoutes.usersReports.replace("{id}", id);

  const response = await httpService.get(url);

  return response;
};

const restore = async (id) => {
  let url =
    `${process.env.REACT_APP_API_BASE_URL}/${ApiRoutes.usersRestore}`.replace(
      "{id}",
      id
    );

  const response = await httpService.patch(url, true);

  return response;
};

/**
 *
 * @param {int} id User id
 * @param {array} reportIds Array of string report ids
 */
const saveReports = async (id, reportIds) => {
  const url = ApiRoutes.usersReports.replace("{id}", id);
  const payload = {
    report_ids: reportIds ?? [],
  };

  const response = await httpService.post(url, payload);

  return response;
};

/**
 *
 * @param {object} payload
 * @returns
 */
const update = async (id, payload) => {
  const url =
    `${process.env.REACT_APP_API_BASE_URL}/${ApiRoutes.usersEdit}`.replace(
      "{id}",
      id
    );

  payload = {
    ...payload,
    role_id: parseInt(payload?.role_id),
  };

  if (!payload.password) {
    delete payload.password;
    delete payload.password_confirmation;
  }

  const response = await httpService.put(url, payload, true);

  return response;
};

//------------------------------------------------
const usersApiService = {
  all,
  get,
  create,
  delete: destroy,
  getReports,
  restore,
  saveReports,
  update,
};

export default usersApiService;
//------------------------------------------------
