import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { AvailableApps, ElementStyle } from "../../constants/misc";

import { StatusCodes } from "http-status-codes";
import { Form } from "react-bootstrap";
import defaults from "../../constants/defaults";
import { Routes } from "../../constants/routes";
import { StorageProps } from "../../constants/storageProps";
import appServices from "../../services/appServices";
import CopyrightFooter from "../CopyrightFooter";
import StyledAlert from "../styled/StyledAlert";
import AppForm from "./Form";
import AppFormLabel from "./FormLabel";
import FormTextField from "./FormTextField";
import SubmittButton from "./SubmittButton";

const LoginForm = ({ inputSize, ...props }) => {
  const [apiResponse, setApiResponse] = useState(defaults.apiResponseDefault);
  const [isBusy, setIsBusy] = useState(false);
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);

  const isProduction = appServices.utility.isProduction();
  const mobileView = appServices.sessionStorage.getProperty(
    StorageProps.mobileView
  );

  let logoImgSrc = props.app
    ? props.app === AvailableApps.LiveDispatch
      ? "live-dispatch-logo.svg"
      : "tattle-systems-logo.png"
    : "nss-logo.svg";
  if (mobileView) logoImgSrc = "NSBI_logo_v3.png";

  const usernameLabel = props.app
    ? props.app === AvailableApps.LiveDispatch
      ? "Username"
      : "Email"
    : "Email or Username";

  //--------------------------------------------
  const defaultValidationSchema = Yup.object().shape({
    username: Yup.string().required().label(usernameLabel),
    password: Yup.string().required().label("Password"),
  });
  let validationSchema = defaultValidationSchema;

  useEffect(() => {
    if (isTwoFactorRequired)
      validationSchema = defaultValidationSchema.concat(
        Yup.object({
          code: Yup.string().required().min(1).label("Security code"),
        })
      );
    else validationSchema = defaultValidationSchema;
  }, [isTwoFactorRequired]);

  //--------------------------------------------

  const handleSubmit = async ({ username, password, otpCode }) => {
    let response = null;
    setApiResponse(defaults.apiResponseDefault);

    if (!props.isModal) {
      //-------------- LOGIN --------------//
      setIsBusy(true);
      response = await appServices.api.auth.logIn(username, password, otpCode);
      setIsBusy(false);

      if (
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.CREATED
      ) {
        if (mobileView) window.location = Routes.reports;
        else window.location = Routes.dashboard;

        return;
      } else if (response.status === StatusCodes.ACCEPTED) {
        setIsTwoFactorRequired(true);
      } else {
        // do something if it fails
        console.warn(response);
      }
    } else {
      //-------------- LINK --------------//
      setIsBusy(true);
      response =
        props.app === AvailableApps.LiveDispatch
          ? await appServices.api.auth.linkLD(username, password, otpCode)
          : await appServices.api.auth.linkTS(username, password, otpCode);
      setIsBusy(false);

      if (
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.CREATED
      ) {
        setApiResponse({ ...defaults.apiResponseDefault, isSuccess: true });

        props.handleClose();
        props.onSuccess();
      } else if (response.status === StatusCodes.ACCEPTED) {
        setIsTwoFactorRequired(true);
      }
    }

    if (!response.ok) {
      setApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response.data),
      });
      setIsBusy(false);

      return;
    }
  };

  return (
    <section className="body-sign">
      <div className="center-sign">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/${logoImgSrc}`}
          height="54"
          alt="Portal logo"
          className="logo float-start"
        />
        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>{" "}
              Sign In
            </h2>
          </div>
          <div className="card-body">
            {(props.isModal && (
              <StyledAlert
                elementStyle={ElementStyle.Default}
                style={{ padding: ".5rem" }}
              >
                Please sign in with <strong>{props.app}</strong>.
              </StyledAlert>
            )) || (
              <StyledAlert style={{ padding: ".5rem" }}>
                You can login with your <strong>Tattle Systems</strong> and/ or{" "}
                <strong>Live Dispatch</strong> credentials.
                <br />
                For further assistance, please contact North Star Systems at{" "}
                <a href="mailto:admin@northstarsystems.ca">
                  admin@northstarsystems.ca
                </a>
                .
              </StyledAlert>
            )}
            <>
              <AppForm
                initialValues={{ username: "", password: "", otpCode: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form.Group className="form-group mb-3">
                  <FormTextField
                    name="username"
                    label={usernameLabel}
                    icon={`${
                      props.app === AvailableApps.TattleSystems
                        ? "bx bx-at"
                        : "bx bx-user"
                    } text-4`}
                    required
                    size={inputSize}
                  />
                  <FormTextField
                    icon="bx bx-lock text-4"
                    LabelComponent={
                      <div className="clearfix">
                        <AppFormLabel
                          className="float-left"
                          label="Password"
                          required
                        />
                      </div>
                    }
                    name="password"
                    size={inputSize}
                    type="password"
                  />
                </Form.Group>

                {isTwoFactorRequired && (
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Security Code</Form.Label>
                    <FormTextField
                      icon="bx bx-key text-4"
                      name="otpCode"
                      required
                      size={inputSize}
                    />

                    <div>
                      The security code was sent to your email (and phone, if
                      provided) and it will expire in a few minutes.
                    </div>
                  </Form.Group>
                )}

                <div className="row">
                  {!props.isModal && (
                    <div className="col-sm-5">
                      {!props.isModal && (
                          <a href={Routes.forgotPassword} className="float-left">
                            Forgot Password?
                          </a>
                        )}
                      {/* <div className="checkbox-custom checkbox-default">
                      <input
                        id="RememberMe"
                        name="rememberme"
                        type="checkbox"
                      />
                      <label htmlFor="RememberMe">Remember Me</label>
                    </div> */}
                    </div>
                  )}
                  <div
                    className={`col-sm-${props.isModal ? "12" : "7"} text-end`}
                  >
                    {props.isModal && (
                      <button
                        type="button"
                        className="btn btn-default modal-dismiss m-2"
                        onClick={props.handleClose}
                      >
                        Cancel
                      </button>
                    )}
                    <SubmittButton title="Sign In" isLoading={isBusy} />
                  </div>
                </div>

                {!apiResponse.isSuccess && apiResponse.errorMessage && (
                  <p className="text-danger">{apiResponse.errorMessage}</p>
                )}

                {/* <span className="mt-3 mb-3 line-thru text-center text-uppercase">
                <span>or</span>
              </span> */}

                {/* <div className="mb-1 text-center">
                <a className="btn btn-facebook mb-3 ml-1 mr-1" href="#">
                  Connect with <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-twitter mb-3 ml-1 mr-1" href="#">
                  Connect with <i className="fab fa-twitter"></i>
                </a>
              </div> */}

                {/* <p className="text-center">
                Don't have an account yet?{" "}
                <a href="pages-signup.html">Sign Up!</a>
              </p> */}
              </AppForm>
              {!props.isModal && !isProduction && (
                <>
                  Don't have an account? Please{" "}
                  <a href={Routes.register}>register now</a>.
                </>
              )}
            </>
          </div>
        </div>

        <CopyrightFooter />
      </div>
    </section>
  );
};

export default LoginForm;
