import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

const increaseNSBIClicks = async () => {
  const response = await httpService.post(ApiRoutes.userLogsIncreaseNSBIClicks);

  return response;
};

const increaseReportClicks = async (reportId) => {
  const url = ApiRoutes.userLogsIncreaseReportClicks.replace(
    "{report_id}",
    reportId
  );

  const response = await httpService.post(url);

  return response;
};

//------------------------------------------------
const userLogsApiService = {
  increaseNSBIClicks,
  increaseReportClicks,
};

export default userLogsApiService;
//------------------------------------------------
