export const Routes = {
  dashboard: "/",
  reports: "/nsbi",
  reports_folder: "/nsbi/folder/:folderId",
  report_details: "/nsbi/:id/view",
  analysis: "/analysis",
  developerResources: "/dev-resources",
  hardware: "/dev-resources/hardware",
  software: "/dev-resources/software",
  apiSummary: "/dev-resources/software/api-summary",
  t3Lite: "/dev-resources/hardware/t3-lite",
  gen3Gen4Specs: "/dev-resources/hardware/gen3-gen4-specs",

  // Profile
  profile: "/profile",
  settings: "/settings",

  // Auth
  login: "/login",
  externalLogin: "/ext-login",
  register: "/register",
  logout: "/logout",

  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",

  admin: "/admin",

  // Users
  users: "/admin/users",
  usersBlocked: "/admin/users?blocked",
  usersCreate: "/admin/users/create",
  usersEdit: "/admin/users/:id/edit",
  usersReports: "/admin/users/:id/reports",
  usersConnectReports: "/admin/users/connect-reports",
  usersDundasReports: "/admin/users/dundas-reports",
  usersSettings: "/admin/users/:id/settings",

  // Tools
  tools: "/admin/tools",
  toolsBiDesigner: "/admin/tools/bi-designer",
  toolsBiDemoDesigner: "/admin/tools/bi-demo-designer",

  test: "/test",
  // errors
  notFound: "/404",
  forbidden: "/403",
  unauthorized: "/401",
};
