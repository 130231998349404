import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

/**
 * Returns all the roles in the database
 * @returns Http response
 */
const all = async () => {
  const response = await httpService.get(ApiRoutes.rolesAll);
  // console.log("All Roles", response);

  return response;
};

/**
 * Returns all the available roles to this user
 * @returns Http response
 */
const available = async () => {
  const response = await httpService.get(ApiRoutes.rolesAvailable);
  // console.log("Available Roles", response);

  return response;
};

//------------------------------------------------
const rolesApiService = {
  all,
  available,
};

export default rolesApiService;
//------------------------------------------------
