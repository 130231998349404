import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

const forgotPassword = async (email) => {
  const response = await httpService.post(ApiRoutes.forgotPassword, {
    email: email,
  });

  return response;
};

const resetPassword = async (token, email, password, passwordConfirmation) => {
  const response = await httpService.post(ApiRoutes.resetPassword, {
    token: token,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
  });

  return response;
};

//------------------------------------------------
const passwordsApiService = {
  forgotPassword,
  resetPassword,
};

export default passwordsApiService;
//------------------------------------------------
