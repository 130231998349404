import { ElementStyle } from "../../constants/misc";
import React from "react";
import StyledLabel from "../styled/StyledLabel";

const AppErrorMessage = ({ error, visible, ...otherProps }) => {
  // hide the control
  if (!visible) return null;

  return <StyledLabel elementStyle={ElementStyle.Danger}>{error}</StyledLabel>;
};

export default AppErrorMessage;
