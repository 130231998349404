import { NodePositions } from "../constants/misc";
import appServices from "../services/appServices";
import { useEffect } from "react";

const useImportCss = (
  resourceUrl,
  condition = true,
  position = NodePositions.Append
) => {
  useEffect(() => {
    if (condition) {
      const css = appServices.utility.appendStyleSheet(resourceUrl, position);
      return () => {
        appServices.utility.removeStyleSheet(css);
      };
    }
  }, [resourceUrl, condition, position]);
};

export default useImportCss;
