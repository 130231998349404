import { useContext, useEffect } from "react";

import { AppContext } from "../context/ContextProvider";
import appServices from "../services/appServices";
import { toast } from "react-toastify";

const useRefreshSystemSettings = () => {
  const isLoggedIn = appServices.user.isLoggedIn();
  const { setSystemSettings } = useContext(AppContext);

  useEffect(() => {
    if (isLoggedIn) getSystemSettingsAsync();
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSystemSettingsAsync = async () => {
    const response = await appServices.api.systemSettings.all();

    if (!response.ok)
      return toast.error("Oops! Could not fetch system settings.");

    setSystemSettings(response?.data);
  };
};

export default useRefreshSystemSettings;
