import Enumerable from "linq";
import LDUser from "../models/LDUser";
import { RoleIds } from "../constants/misc";
import { StatusCodes } from "http-status-codes";
import { StorageProps } from "../constants/storageProps";
import TSUser from "../models/TSUser";
import User from "../models/User";
import localStorageService from "./localStorageService";
import sessionStorageService from "./sessionStorageService";

const setAuthProperties = (userData) => {
  const user = userService.mapUser(userData);

  if (user) {
    localStorageService.removeProperty(StorageProps.currentUser);
    localStorageService.removeProperty(StorageProps.portalAccessToken);

    sessionStorageService.setProperty(StorageProps.currentUser, user);
    sessionStorageService.setProperty(
      StorageProps.portalAccessToken,
      user.portal_access_token
    );
  }

  localStorageService.setProperty(
    StorageProps.buildNumber,
    process.env.REACT_APP_BUILD
  );
};

function mapUser(data) {
  const user = new User();

  user.id = data?.id;
  user.name = data?.name;
  user.email = data?.email;
  user.createdAt = data?.createdAt;
  user.portal_access_token = data?.portal_access_token;
  user.ts = mapTSUser(data?.ts);
  user.ld = mapLDUser(data?.ld);
  user.roles = data?.roles ?? [];
  user.meta = data?.meta;
  user.logo = data?.logo;

  return user;
}

function mapTSUser(data) {
  const tsUser = new TSUser();
  tsUser.user_id = data?.user_id;
  tsUser.name = data?.name;
  tsUser.email = data?.email;
  tsUser.username = data?.username;
  tsUser.jsession_id = data?.jsession_id;

  return tsUser;
}

function mapLDUser(data) {
  const ldUser = new LDUser();
  ldUser.user_id = data?.user_id;
  ldUser.name = data?.name;
  ldUser.email = data?.email;
  ldUser.username = data?.username;
  ldUser.client_name = data?.client_name;
  ldUser.access_token = data?.access_token;
  ldUser.refresh_token = data?.refresh_token;

  return ldUser;
}

function isLoggedIn() {
  const currentUser = sessionStorageService.getProperty(
    StorageProps.currentUser
  );
  const token = sessionStorageService.getProperty(
    StorageProps.portalAccessToken
  );
  // const isLoggedIn =
  //   (currentUser?.ld?.user_id != null || currentUser?.ts?.user_id > 0) === true;
  const isLoggedIn = currentUser !== null && token !== null;

  return isLoggedIn;
}

function isAdmin(user = null) {
  // if the user is not provided we imply to check the current user
  user = user ?? sessionStorageService.getProperty(StorageProps.currentUser);
  const hasAdminRole = Enumerable.from(user?.roles).any(
    (x) => x.id === RoleIds.Admin
  );

  return hasAdminRole;
}

function hasRole(user, roleId) {
  const hasThisRole = Enumerable.from(user?.roles).any((x) => x.id === roleId);
  return hasThisRole;
}

function removeTattleUserIfUnauthorized(error) {
  if (error && error.status === StatusCodes.UNAUTHORIZED) {
    const currentUser = sessionStorageService.getProperty(
      StorageProps.currentUser
    );

    sessionStorageService.setProperty(StorageProps.currentUser, {
      ...currentUser,
      ts: new TSUser(),
    });

    return true;
  }

  return false;
}

/**
 * Returns the meta value for a given key
 * @param {object} user The user
 * @param {string} key The meta_key
 * @returns The value or empty string
 */
function getMetaData(user, key) {
  const metaValue = Enumerable.from(user?.meta).firstOrDefault(
    (x) => x.key === key
  )?.value;

  if (metaValue === null || metaValue === undefined) {
    return "";
  }

  return metaValue;
}

//------------------------------------------------
const userService = {
  getMetaData,
  hasRole,
  isAdmin,
  isLoggedIn,
  mapLDUser,
  mapTSUser,
  mapUser,
  removeTattleUserIfUnauthorized,
  setAuthProperties,
};

export default userService;
//------------------------------------------------
