import { Routes } from "../constants/routes";
import { StorageProps } from "../constants/storageProps";
import appServices from "../services/appServices";
import { useEffect } from "react";

const useValidateBuildNumber = (condition = true) => {
  useEffect(() => {
    // check if the build number is too old or not
    if (condition) {
      validateBuildNumberOrRedirectAsync();
    }
  }, [condition]);

  /**
   * Redirect to login if the build number is not up to date
   */
  async function validateBuildNumberOrRedirectAsync() {
    const currentBuildNumber =
      appServices.localStorage.getProperty(StorageProps.buildNumber) ?? 0;
    const minBuildNumber = parseInt(process.env.REACT_APP_BUILD_MIN);

    // // console.log("currentBuildNumber", currentBuildNumber);
    // console.log("minBuildNumber", minBuildNumber);

    if (currentBuildNumber < minBuildNumber) {
      try {
        await appServices.api.auth.logOut();
      } catch (ex) {
        // if (ex.response) {
        //   toast.error(logService.extractErrorMessage(ex));
        // }
      } finally {
        window.location = Routes.login;
      }
    }
  }
};

export default useValidateBuildNumber;
