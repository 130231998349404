import React from "react";
import { StorageProps } from "../constants/storageProps";
import appServices from "../services/appServices";

const PageHeaderV2 = (props) => {
  const mobileView = appServices.sessionStorage.getProperty(
    StorageProps.mobileView
  );

  if (mobileView) return <div style={{ height: 19, marginBottom: 20 }} />;

  return (
    <header className="page-header" style={props.style ?? {}}>
      <h2 className="font-weight-semibold text-6">{props.title}</h2>
      {props.children}
    </header>
  );
};

export default PageHeaderV2;
