import { Redirect, Route } from "react-router-dom";

import LayoutSignedIn from "./LayoutSignedIn";
import React from "react";
import { Routes } from "../constants/routes";
import appServices from "../services/appServices";
import useAuthorizeRoles from "../hooks/useAuthorizeRoles";
import useValidateBuildNumber from "../hooks/useValidateBuildNumber";

const PrivateRoute = ({ children, roles = [], ...props }) => {
  const isLoggedIn = appServices.user.isLoggedIn();

  useValidateBuildNumber(isLoggedIn);
  const isAuthorized = useAuthorizeRoles(roles);

  if (!isAuthorized) {
    return <Redirect to={Routes.forbidden} />;
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        isLoggedIn === true ? (
          <LayoutSignedIn {...props}>{children}</LayoutSignedIn>
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
