import { ElementStyle } from "../../constants/misc";
import React from "react";

const LoadingButton = ({
  children: title,
  type = "button",
  isLoading,
  isDisabled,
  icon = "fa fa-spinner fa-spin me-2",
  onClick,
  elementStyle = ElementStyle.Primary,
  ...props
}) => {
  const disabled = isDisabled ?? isLoading;
  return (
    <button
      type={type}
      className={`btn btn-${elementStyle} ${props.className ?? ""}`} // mt-2 mb-2
      onClick={onClick}
      disabled={disabled ? "disabled" : null}>
      {isLoading && <i className={icon} />} {title}
    </button>
  );
};

export default LoadingButton;
