import { ElementStyle } from "../../constants/misc";
import { Modal } from "react-bootstrap";
import React from "react";
import StyledAlert from "../styled/StyledAlert";
import StyledCard from "../styled/StyledCard";
import UserForm from "../forms/UserForm";

const AddOrEditUserDialog = ({
  title,
  user,
  apiResponse,
  inputSize,
  isBusy,
  submitButtonText,
  withRole,
  onSubmit,
  show,
  onHide,
  ...props
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}>
      <StyledCard title={title}>
        <UserForm
          user={user}
          inputSize={inputSize}
          hasFloatingLabel={false}
          apiResponse={apiResponse}
          isBusy={isBusy}
          submitButtonText={submitButtonText}
          withNotes
          withRole={withRole}
          onSubmit={onSubmit}
          onCancel={onHide}
          isModal
        />
        {apiResponse?.isSuccess === false && (
          <StyledAlert
            elementStyle={ElementStyle.Warning}
            style={{ padding: ".5rem", display: "inline-block" }}>
            {apiResponse?.errorMessage}
          </StyledAlert>
        )}
      </StyledCard>
    </Modal>
  );
};

export default AddOrEditUserDialog;
