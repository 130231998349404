import React, { useMemo, useState } from "react";

import Enumerable from "linq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortDirections } from "../../constants/misc";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";
import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";

const NSDataTableHeader = ({
  headers,
  sortBy,
  sortDirection,
  onSort,
  ...props
}) => {
  const [headersState, setHeadersState] = useState(headers);

  const handleHeaderClick = (event, header) => {
    event.preventDefault();

    if (header.sortable) {
      header.sortDirection =
        header.sortDirection === SortDirections.Ascending
          ? SortDirections.Descending
          : SortDirections.Ascending;

      const newHeadersState = [...headersState];
      const index = Enumerable.from(newHeadersState).indexOf(
        (x) => x.name === header.name
      );
      newHeadersState[index] = header;
      setHeadersState(newHeadersState);

      onSort(header.name, header.sortDirection);
    }
  };

  const headerColumns = useMemo(() => {
    return headersState?.map((header) => {
      // set the correct sort icon
      const sortIcon = (sortBy === header.name &&
        header.sortDirection &&
        (header.sortDirection === SortDirections.Descending ? (
          <FontAwesomeIcon
            icon={faSortAmountDown}
            className="show sort-icon ms-2"
          />
        ) : (
          <FontAwesomeIcon
            icon={faSortAmountDownAlt}
            className="show sort-icon ms-2"
          />
        ))) || (
        <FontAwesomeIcon
          icon={faSortAmountDownAlt}
          className="sort-icon ms-2"
        />
      );

      return (
        <th
          key={JSON.stringify(header)}
          name={header.name}
          className={`${header.className ?? ""} ${
            header.sortable ? "sortable cur-pointer" : ""
          }`.trim()}
          onClick={(event) => handleHeaderClick(event, header)}>
          <span className="ws-nowrap">
            {header.text} {sortIcon}
          </span>
        </th>
      );
    });
  }, [headersState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <thead>
      <tr>{headerColumns}</tr>
    </thead>
  );
};

export default NSDataTableHeader;
