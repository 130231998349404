import * as Yup from "yup";

import { ElementStyle, MetaDataKeys } from "../../constants/misc";
import React, { useEffect, useState } from "react";

import AppForm from "./Form";
import { Form } from "react-bootstrap";
import FormSelectField from "./FormSelectField";
import FormTextField from "./FormTextField";
import FormToggleField from "./FormToggleField";
import StyledLabel from "../styled/StyledLabel";
import SubmittButton from "./SubmittButton";
import { UserContext } from "../../context/ContextProvider";
import appServices from "../../services/appServices";
import schemas from "../../constants/schemas";
import { useContext } from "react";
import useImportCss from "../../hooks/userImportCss";

const UserForm = ({
  user,
  inputSize,
  hasFloatingLabel,
  apiResponse,
  submitButtonText = "Register",
  withRole,
  withNotes,
  onSubmit = () => {},
  onCancel = () => {},
  isModal,
  ...props
}) => {
  const [roles, setRoles] = useState([]);
  const [dundasProjects, setDundasProjects] = useState([]);
  const [dundasEnabled, setDundasEnabled] = useState(
    !!Number(
      appServices.user.getMetaData(user, MetaDataKeys.Settings_NsbiEnabled)
    )
  );
  const [isBusy, setIsBusy] = useState(false);
  const { currentUser } = useContext(UserContext);
  useImportCss(`${process.env.PUBLIC_URL}/assets/css/ios-switch.css`);

  let validationSchema = user ? schemas.editUser : schemas.createUser;
  if (withRole) {
    validationSchema = validationSchema.concat(
      Yup.object().shape({
        role_id: Yup.number()
          .required()
          .min(1, "Role is a required field")
          .label("Role"),
      })
    );
  }

  useEffect(() => {
    fetchDundasProjectsAsync();
  }, []);

  useEffect(() => {
    if (withRole) {
      fetchAvailableRolesAsync();
    }
  }, [withRole]);

  const fetchAvailableRolesAsync = async () => {
    setIsBusy(true);
    const response = await appServices.api.roles.available();
    setIsBusy(false);

    if (response.ok) setRoles(response.data);
  };

  const fetchDundasProjectsAsync = async () => {
    setIsBusy(true);
    const response = await appServices.api.dundas.getAllProjects();
    setIsBusy(false);

    if (response.ok) setDundasProjects(response.data);
  };

  const handleNsbiToggleChange = (checked, { setFieldValue }) => {
    setDundasEnabled(checked);
    if (!checked) setFieldValue("project_id", "");
  };

  const handleSubmit = ({
    name,
    email,
    password,
    password_confirmation,
    company_name,
    primary_phone_number,
    secondary_phone_number,
    nsbiEnabled,
    project_id,
    role_id,
    notes,
    admin_notes,
  }) => {
    const userData = {
      name: name,
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      meta: [
        {
          key: MetaDataKeys.CompanyName,
          value: company_name,
        },
        {
          key: MetaDataKeys.PrimaryPhoneNumber,
          value: primary_phone_number,
        },
        {
          key: MetaDataKeys.SecondaryPhoneNumber,
          value: secondary_phone_number,
        },
        {
          key: MetaDataKeys.Settings_NsbiEnabled,
          value: nsbiEnabled,
        },
      ],
      role_id: role_id,
      project_id: project_id,
    };

    if (withNotes) {
      userData.meta.push({
        key: MetaDataKeys.Notes,
        value: notes,
      });

      if (appServices.user.isAdmin()) {
        userData.meta.push({
          key: MetaDataKeys.AdminNotes,
          value: admin_notes,
        });
      }
    }

    onSubmit(userData);
  };

  return (
    <AppForm
      enableReinitialize={false} // otherwise it clears the fields when you change the toggle
      initialValues={{
        id: user?.id,
        name: user?.name,
        email: user?.email,
        company_name: appServices.user.getMetaData(
          user,
          MetaDataKeys.CompanyName
        ),
        nsbiEnabled: dundasEnabled,
        password: "",
        password_confirmation: "",
        primary_phone_number: appServices.user.getMetaData(
          user,
          MetaDataKeys.PrimaryPhoneNumber
        ),
        project_id: user?.dundas?.dundas_project_id,
        secondary_phone_number: appServices.user.getMetaData(
          user,
          MetaDataKeys.SecondaryPhoneNumber
        ),
        notes: appServices.user.getMetaData(user, MetaDataKeys.Notes),
        admin_notes: appServices.user.getMetaData(
          user,
          MetaDataKeys.AdminNotes
        ),
        role_id: user?.roles[0]?.id ?? -1,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <div className="row">
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="bx bxs-user-account text-4"
            label="Name"
            name="name"
            required
            size={inputSize}
          />
          {apiResponse?.errors?.name && (
            <div>
              <StyledLabel elementStyle={ElementStyle.Danger}>
                {apiResponse?.errors?.name}
              </StyledLabel>
            </div>
          )}
        </div>
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="bx bx-at text-4"
            label="Email"
            name="email"
            required
            size={inputSize}
            type="email"
          />
          {apiResponse?.errors?.email && (
            <div>
              <StyledLabel elementStyle={ElementStyle.Danger}>
                {apiResponse?.errors?.email}
              </StyledLabel>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="bx bx-lock text-4"
            name="password"
            label="Password"
            required={!user?.id}
            size={inputSize}
            type="password"
          />
          {apiResponse?.errors?.password && (
            <div>
              <StyledLabel elementStyle={ElementStyle.Danger}>
                {apiResponse?.errors?.password}
              </StyledLabel>
            </div>
          )}
        </div>
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="bx bx-lock text-4"
            label="Confirm Password"
            name="password_confirmation"
            required={!user?.id}
            size={inputSize}
            type="password"
          />
          {apiResponse?.errors?.password_confirmation && (
            <div>
              <StyledLabel elementStyle={ElementStyle.Danger}>
                {apiResponse?.errors?.password_confirmation}
              </StyledLabel>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="bx bx-buildings text-4"
            label="Company Name"
            name="company_name"
            size={inputSize}
          />
        </div>
        <div className="col">
          {withRole && (
            <>
              <FormSelectField
                disabled={currentUser?.id === user?.id}
                icon="bx bx-shield-quarter text-4"
                label="Role"
                name="role_id"
                required>
                <option value="-1"></option>
                {roles?.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </FormSelectField>
              {apiResponse?.errors?.role_id && (
                <div>
                  <StyledLabel elementStyle={ElementStyle.Danger}>
                    {apiResponse?.errors?.role_id}
                  </StyledLabel>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="fas fa-mobile-alt text-4"
            label="Primary Phone"
            name="primary_phone_number"
            size={inputSize}
          />
        </div>
        <div className="col">
          <FormTextField
            hasFloatingLabel={hasFloatingLabel}
            icon="fas fa-mobile-alt text-4"
            label="Secondary Phone"
            name="secondary_phone_number"
            size={inputSize}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <FormToggleField
            label="Uses NSBI"
            name="nsbiEnabled"
            onChange={handleNsbiToggleChange}
            visible={appServices.user.isAdmin()}
          />
        </div>
        <div className="col">
          <FormSelectField
            disabled={!dundasEnabled}
            icon="fas fa-project-diagram text-4"
            label="NSBI Project"
            name="project_id">
            <option value=""></option>
            {dundasProjects?.map((project) => (
              <option key={project.projectId} value={project.projectId}>
                {project.name}
              </option>
            ))}
          </FormSelectField>
        </div>
      </div>

      {withNotes && (
        <FormTextField
          as="textarea"
          hasFloatingLabel={hasFloatingLabel}
          icon="bx bx-notepad text-4"
          label="Notes"
          name="notes"
          size={inputSize}
        />
      )}

      {appServices.user.isAdmin() && (
        <FormTextField
          as="textarea"
          hasFloatingLabel={hasFloatingLabel}
          icon="bx bx-notepad text-4"
          label="Admin Notes"
          name="admin_notes"
          size={inputSize}
        />
      )}

      <Form.Group className="text-end">
        {isModal && (
          <button
            type="button"
            className="btn btn-default modal-dismiss me-2"
            onClick={onCancel}>
            Cancel
          </button>
        )}
        <SubmittButton
          title={submitButtonText}
          isLoading={isBusy || props.isBusy}
        />
      </Form.Group>
    </AppForm>
  );
};

export default UserForm;
