import { ElementStyle } from "../../constants/misc";
import React from "react";

const StyledAlert = ({
  elementStyle = ElementStyle.Default,
  children,
  showCloseButton,
  ...props
}) => {
  return (
    <div
      className={`alert alert-${elementStyle} alert-dismissible fade show`}
      {...props}
      role="alert">
      {children}
      {showCloseButton && (
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-hidden="true"
          aria-label="Close"></button>
      )}
    </div>
  );
};

export default StyledAlert;
