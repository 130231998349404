import File from "./File";
import LDUser from "./LDUser";
import TSUser from "./TSUser";

class User {
  constructor() {
    this.id = null;
    this.name = null;
    // this.password = null;
    // this.password_confirmation = null;
    this.email = null;
    this.createdAt = null;
    this.portal_access_token = null;
    this.ld = new LDUser(); // Live Dispatch User
    this.ts = new TSUser(); // Tattle Systems User
    this.roles = []; // Role[]
    this.meta = []; // MetaData[]
    this.logo = new File(); // File
  }
}

export default User;
