import React from "react";

const AppContext = React.createContext();

//------------------------------------------------

const modalsContextTemplate = {
  showLDLoginModal: false,
  setShowLDLoginModal: () => {},
  showTSLoginModal: false,
  setShowTSLoginModal: () => {},
  showConfirmationModal: false,
  setShowConfirmationModal: () => {},
};

const ModalsContext = React.createContext(modalsContextTemplate);

//------------------------------------------------

const userContextTemplate = {
  currentUser: {},
  onCurrentUserChanged: () => {},
};

const UserContext = React.createContext(userContextTemplate);

//------------------------------------------------

export { AppContext, ModalsContext, UserContext };
