import React, { useEffect, useState } from "react";

import Pagination from "react-bootstrap/Pagination";

const NSDataTablePagination = ({
  // properties
  page = 1,
  from,
  to,
  pageSize,
  lastPage,
  total,
  firstLink,
  prevLink,
  links,
  nextLink,
  lastLink,
  minimal,
  // events
  onPageChange,
  onPageSizeChange,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePageChange = (page, link) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    onPageSizeChange(event.target.value);
  };

  return (
    <div className="row">
      <div className="col col-sm-12 col-md-5">
        <div className="dataTables_info dataTables_length mt-1">
          <label>
            <select
              className="form-select form-select-sm"
              aria-controls="users-datatable"
              value={rowsPerPage}
              onChange={handlePageSizeChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{" "}
            rows per page
          </label>
        </div>
        {from != null && to != null && (
          <div className="dataTables_info" role="status" aria-live="polite">
            <span>
              Showing {from}-{to} of {total} rows
            </span>
          </div>
        )}
      </div>
      <div className="col-sm-12 col-md-7">
        {lastPage > 1 && (
          <div className="dataTables_paginate paging_simple_numbers" {...props}>
            <Pagination>
              {/* Previous */}
              <Pagination.Prev
                className="paginate_button"
                disabled={!prevLink}
                onClick={() => handlePageChange(currentPage - 1, prevLink)}>
                <span>Previous</span>
              </Pagination.Prev>

              {/* Pages */}
              {(!minimal &&
                links?.map((item, index) => {
                  if (item.label === "...") {
                    return <Pagination.Ellipsis key={index} disabled />;
                  }

                  const itemLabel = item.label
                    .replace("&laquo;", "")
                    .replace("&raquo;", "")
                    .trim();

                  return (
                    <Pagination.Item
                      key={index}
                      className="paginate_button"
                      active={parseInt(itemLabel) === currentPage}
                      // active={item.active}
                      onClick={() =>
                        handlePageChange(parseInt(itemLabel), item.url)
                      }>
                      {itemLabel}
                    </Pagination.Item>
                  );
                })) || (
                <>
                  <Pagination.Item
                    className="paginate_button"
                    active={currentPage === 1}
                    onClick={() => handlePageChange(1, firstLink)}>
                    1
                  </Pagination.Item>
                  <Pagination.Ellipsis className="paginate_button" disabled />
                  <Pagination.Item
                    className="paginate_button"
                    active={currentPage === lastPage}
                    onClick={() => handlePageChange(lastPage, lastLink)}>
                    {lastPage}
                  </Pagination.Item>
                </>
              )}

              {/* Next */}
              <Pagination.Next
                className="paginate_button"
                disabled={!nextLink}
                onClick={() => handlePageChange(currentPage + 1, nextLink)}>
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        )}
      </div>
    </div>
  );
};

export default NSDataTablePagination;
