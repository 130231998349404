import { useEffect, useState } from "react";

import { StorageProps } from "../constants/storageProps";
import appServices from "../services/appServices";

const useAuthorizeRoles = (roles = []) => {
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    if (roles?.length === 0) {
      setIsAuthorized(true);
      return;
    }

    const currentUser = appServices.sessionStorage.getProperty(
      StorageProps.currentUser
    );
    const hasRole = roles.some((roleId) => {
      return appServices.user.hasRole(currentUser, roleId);
    });

    // The user does not have any of the roles provided
    // window.location = Routes.not_found;
    setIsAuthorized(hasRole);

    return () => {};
  }, [roles]);

  return isAuthorized;
};

export default useAuthorizeRoles;
