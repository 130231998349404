import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

/**
 * Deletes a file from the db and from S3
 * @param {int} id The file id
 * @returns
 */
const destroy = async (id) => {
  let url =
    `${process.env.REACT_APP_API_BASE_URL}/${ApiRoutes.filesDelete}`.replace(
      "{id}",
      id
    );

  const response = await httpService.delete(url, true);

  return response;
};

/**
 * Uploads a logo for the current user
 * @param {*} formData
 * @returns
 */
const uploadLogo = async (formData) => {
  // TODO
  const response = await httpService.post(
    ApiRoutes.filesUploadLogo,
    formData,
    {},
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

//------------------------------------------------
const filesApiService = {
  delete: destroy,
  uploadLogo,
};

export default filesApiService;
//------------------------------------------------
