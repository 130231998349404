import { ElementStyle } from "../../constants/misc";
import React from "react";

const StyledLabel = ({ elementStyle = ElementStyle.Default, ...props }) => {
  return (
    <label className={`text-${elementStyle}`} {...props}>
      {props.children}
    </label>
  );
};

export default StyledLabel;
