import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import CustomSpinner from "../../components/CustomSpinner";
import { Routes } from "../../constants/routes";
import { StatusCodes } from "http-status-codes";
import appServices from "../../services/appServices";
import { toast } from "react-toastify";

const ExternalLoginPage = (props) => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const isLoggedIn = appServices.user.isLoggedIn();
  const [isBusy, setIsBusy] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      toast.error("No token provided.");
      history.push(Routes.login);
      return;
    }

    tryLoginAsync(token);

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function tryLoginAsync(token) {
    try {
      setIsBusy(true);

      if (isLoggedIn) {
        await appServices.api.auth.logOut();
      }

      const response = await appServices.api.auth.externalLogIn(token);

      if (response.status === StatusCodes.OK) {
        window.location = Routes.dashboard;
      } else {
        // do something if it fails
        console.warn(response);
        toast.error(
          "We could not log you in. Please try with your credentials."
        );
        history.push(Routes.login);
      }

      setIsBusy(false);
    } catch (ex) {
      toast.error(appServices.log.extractErrorMessage(ex));
      setIsBusy(false);
      history.push(Routes.login);
    }
  }

  return (
    <div className="text-center" style={{ height: "100%", margin: "auto" }}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/img/nss-logo.svg`}
        width="350"
        // height="35"
        alt="Portal"
      />
      {isBusy && <h3>Please wait...</h3>}
      {/* <LoadingOverlay /> */}
      <CustomSpinner isActive={isBusy} className="mt-3" />
    </div>
  );
};

export default ExternalLoginPage;
