import LoadingButton from "../buttons/LoadingButton";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import StyledCard from "../styled/StyledCard";

const ConfirmationDialog = ({
  title,
  isFeatured,
  elementStyle,
  isBusy,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  ...props
}) => {
  const handleCancel = () => {
    props.onHide();

    if (onCancel) onCancel();
  };

  return (
    <Modal
      dialogClassName="link-app-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...props}>
      <StyledCard
        title={title}
        elementStyle={elementStyle}
        isFeatured={isFeatured}>
        {props.children}
        <div className="row">
          <div className="col text-end">
            <button
              className="btn btn-default modal-dismiss mt-2 me-2"
              onClick={handleCancel}>
              {cancelButtonText}
            </button>
            <LoadingButton
              className="modal-dismiss mt-2"
              elementStyle={elementStyle}
              isDisabled={isBusy}
              onClick={onConfirm}>
              {confirmButtonText}
            </LoadingButton>
          </div>
        </div>
      </StyledCard>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  isFeatured: PropTypes.bool,
};

export default ConfirmationDialog;
