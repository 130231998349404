import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

const all = async () => {
  const response = await httpService.get(ApiRoutes.systemSettingsAll);

  return response;
};

const saveMany = async (payload) => {
  const response = await httpService.post(
    ApiRoutes.systemSettingsSaveMany,
    payload
  );

  return response;
};

//------------------------------------------------
const systemSettingsApiService = {
  all,
  saveMany,
};

export default systemSettingsApiService;
//------------------------------------------------
