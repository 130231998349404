import { format } from "date-fns";
import React from "react";
import appServices from "../services/appServices";

const ReportPreviewCard = ({
  className,
  report,
  onClick = () => {},
  loading = false,
}) => {
  // console.log(report);
  const reportIconPath = appServices.utility.getReportImage(report?.type);

  const handleClick = () => {
    onClick(report);
  };

  return (
    <section
      className={`card card-featured-left card-featured-primary highlight-on-hover mb-1 ${className}`}
      onClick={handleClick}
    >
      <div className="card-body report-card-body">
        <div className="widget-summary widget-summary-xlg">
          <div className="widget-summary-col widget-summary-col-icon valign-middle">
            {/* <div className="summary-icon bg-secondary"> */}
            {/* <i className={reportIconClass}></i> */}
            <img
              src={reportIconPath}
              className="report-preview-icon"
              alt="Report preview"
            />
            {/* </div> */}
          </div>
          <div className="widget-summary-col report-name valign-middle">
            <div className="summary d-table-cell">
              <h4 className="title text-capitalize valign-middle">
                {report.name}
              </h4>
              {/* <div className="info">
                <span className="text-black-50 text-capitalize">
                  {report.type}
                </span>
              </div> */}
            </div>
            {/* <div className="summary-footer">
              <label className="cur-pointer">Click to view</label>
            </div> */}
          </div>
          <div
            className="valign-middle"
            style={{
              display: "table-cell",
              textAlign: "right",
              width: "max-content",
            }}
          >
            <span className="text-muted">
              Added on {format(new Date(report?.createdAt), "MMM d, yyyy")}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReportPreviewCard;
