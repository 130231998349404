import React from "react";

import ReportFolder from "./ReportFolder";
import ReportPreviewCards from "./ReportPreviewCards";

const UserReportsList = ({
  dundasFolders,
  dundasDashboards,
  ...otherProps
}) => {
  return (
    <>
      <div className="d-flex flex-row flex-wrap gap-3 mb-3">
        {dundasFolders?.map((folder, index) => (
          <ReportFolder key={`dundas-folder-${index}`} folder={folder} />
        ))}
      </div>

      <ReportPreviewCards dundasDashboards={dundasDashboards} />
    </>
  );
};

export default UserReportsList;
