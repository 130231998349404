import React from "react";

const ToolBar = (props) => {
  return (
    <div className="inner-toolbar clearfix">
      <ul>
        <li className="right">
          <ul className="nav nav-pills nav-pills-primary">{props.children}</ul>
        </li>
      </ul>
    </div>
  );
};

export default ToolBar;
