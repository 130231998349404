// import "../css/full-screen-content.css";

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import useImportCss from "../../hooks/userImportCss";
import appServices from "../../services/appServices";

const ReportDetailsPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [dundasLogOnToken, setDundasLogOnToken] = useState();

  useImportCss(
    `${process.env.PUBLIC_URL}/assets/css/full-screen-content.css`,
    isSuccess
  );

  useEffect(() => {
    getDundasTokenAsync(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDundasTokenAsync = async () => {
    try {
      setIsLoading(true);
      const response = await appServices.api.dundas.getLogOnToken();
      // console.log("LogOn Token", response);

      if (!response.ok) return;

      setDundasLogOnToken(response.data.logOnToken);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    dundasLogOnToken && (
      <>
        <div
          style={{
            height: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
        </div>
        <iframe
          src={`${process.env.REACT_APP_DUNDAS_URL}/${location?.state?.type}/${id}?logonTokenId=${dundasLogOnToken}`}
          title={`${location?.state?.type} View`}
          className="dundas-bi-embed"
        />
      </>
    )
  );

  };

export default ReportDetailsPage;
