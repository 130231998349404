import { StatusCodes } from "http-status-codes";
import { isMobile } from "react-device-detect";
import { ApiRoutes } from "../../constants/apiRoutes";
import { StorageProps } from "../../constants/storageProps";
import LDUser from "../../models/LDUser";
import TSUser from "../../models/TSUser";
import httpService from "../httpService";
import sessionStorageService from "../sessionStorageService";
import userService from "../userService";

/**
 * Logs in a user by token in url
 * @param {string} token The portal access token
 * @returns The logged in user
 */
const externalLogIn = async (token) => {
  const url = `${ApiRoutes.externalLogin}`.replace("{token}", token);

  const response = await httpService.get(url);

  if (response?.status === StatusCodes.OK) {
    userService.setAuthProperties(response.data);
  }

  return response;
};

/**
 * Validates the TS jsession_id
 * @returns
 */
const getTattleUser = async () => {
  const currentUser = sessionStorageService.getProperty(
    StorageProps.currentUser
  );
  const jSessionId = currentUser?.ts?.jsession_id;

  try {
    // TODO
    const response = await httpService.get(
      ApiRoutes.getTattleUser,
      {},
      {
        headers: { JSESSIONID: jSessionId },
      }
    );

    if (response?.status === StatusCodes.OK) {
      const tsUser = userService.mapTSUser(response.data);
      tsUser.jsession_id = jSessionId;
      // console.log("TS User", tsUser);

      sessionStorageService.setProperty(StorageProps.currentUser, {
        ...currentUser,
        portal_access_token: currentUser.portal_access_token,
        ts: tsUser,
      });
    }

    return response;
  } catch (ex) {
    sessionStorageService.setProperty(StorageProps.currentUser, {
      ...currentUser,
      portal_access_token: currentUser.portal_access_token,
      ts: new TSUser(),
    });

    throw ex;
  }
};

/**
 * Links the current user to an existing LD account
 * @param {string} username
 * @param {string} password
 * @param {string} otpCode
 * @returns
 */
const linkLD = async (username, password, otpCode) => {
  // console.log("linkLD url:", url);

  const response = await httpService.post(ApiRoutes.linkLD, {
    username,
    password,
    authKey: otpCode,
  });

  if (
    response?.status === StatusCodes.OK ||
    response?.status === StatusCodes.CREATED
  ) {
    const ldUser = userService.mapLDUser(response.data);

    const currentUser = sessionStorageService.getProperty(
      StorageProps.currentUser
    );
    if (currentUser) {
      currentUser.ld = ldUser;
      sessionStorageService.setProperty(StorageProps.currentUser, currentUser);
    }
  }

  return response;
};

/**
 * Links the current user to an existing TS account
 * @param {string} username
 * @param {string} password
 * @param {string} otpCode
 * @returns
 */
const linkTS = async (username, password, otpCode) => {
  const response = await httpService.post(ApiRoutes.linkTS, {
    username,
    password,
    authKey: otpCode,
  });

  if (
    response?.status === StatusCodes.OK ||
    response?.status === StatusCodes.CREATED
  ) {
    const tsUser = userService.mapTSUser(response.data);

    const currentUser = sessionStorageService.getProperty(
      StorageProps.currentUser
    );
    if (currentUser) {
      currentUser.ts = tsUser;
      sessionStorageService.setProperty(StorageProps.currentUser, currentUser);
    }
  }

  return response;
};

/**
 * Logs in the user with LD or TS
 * @param {string} username
 * @param {string} password
 * @param {string} otpCode
 * @returns
 */
const logIn = async (username, password, otpCode) => {
  // validate csrf token
  // await httpService.get(
  //   `${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`
  // );

  const payload = {
    username,
    password,
    authKey: otpCode,
  };
  const response = await httpService.post(ApiRoutes.login, payload);
  // console.log(JSON.stringify(response, null, 4));
  // alert("stop");

  if (
    response?.status === StatusCodes.OK ||
    response?.status === StatusCodes.CREATED
  ) {
    userService.setAuthProperties(response.data);
  }

  return response;
};

/**
 * Logs out the user from the backend
 * @returns
 */
const logOut = async () => {
  try {
    if (!isMobile) {
      const response = await httpService.post(ApiRoutes.logOut);

      return response;
    }
  } finally {
    const sessionMobileView = sessionStorageService.getProperty(
      StorageProps.mobileView
    );

    sessionStorageService.clear();

    sessionStorageService.setProperty(
      StorageProps.mobileView,
      sessionMobileView
    );
  }
};

/**
 * Returns the current user
 * @returns
 */
const me = async () => {
  const response = await httpService.get(ApiRoutes.me);
  // console.log("Me", response);

  if (response?.status === StatusCodes.OK) {
    userService.setAuthProperties(response.data);
  }

  return response;
};

const signUp = async (payload) => {
  const response = await httpService.post(ApiRoutes.signUp, payload);

  if (
    response?.status === StatusCodes.OK ||
    response?.status === StatusCodes.CREATED
  ) {
    userService.setAuthProperties(response.data);
  }

  return response;
};

/**
 * Unlinks LD
 * @returns
 */
const unlinkLD = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/${ApiRoutes.unlinkLD}`;
  // console.log("linkLD url:", url);

  const response = await httpService.delete(url, true);

  if (response?.status === StatusCodes.OK) {
    const currentUser = sessionStorageService.getProperty(
      StorageProps.currentUser
    );
    sessionStorageService.setProperty(StorageProps.currentUser, {
      ...currentUser,
      ld: new LDUser(),
    });
  }

  // console.log("unlinkLD", response);
  return response;
};

/**
 * Unlinks TS
 * @returns
 */
const unlinkTS = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/${ApiRoutes.unlinkTS}`;
  // console.log("linkLD url:", url);

  const response = await httpService.delete(url, true);

  if (response?.status === StatusCodes.OK) {
    const currentUser = sessionStorageService.getProperty(
      StorageProps.currentUser
    );
    sessionStorageService.setProperty(StorageProps.currentUser, {
      ...currentUser,
      ts: new TSUser(),
    });
  }

  // console.log("unlinkTS", response);
  return response;
};

//------------------------------------------------
const authApiService = {
  externalLogIn,
  getTattleUser,
  linkLD,
  linkTS,
  logIn,
  logOut,
  me,
  signUp,
  unlinkLD,
  unlinkTS,
};

export default authApiService;
//------------------------------------------------
