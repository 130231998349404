import { Formik } from "formik";
import React from "react";

const AppForm = ({
  enableReinitialize = true,
  initialValues,
  onSubmit = () => {},
  validationSchema,
  ...otherProps
}) => {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      {...otherProps}>
      {() => (
        <form>
          <div>{otherProps.children}</div>
        </form>)}
    </Formik>
  );
};

export default AppForm;
