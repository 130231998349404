class TSUser {
  constructor() {
    this.user_id = null;
    this.username = null;
    this.email = null;
    this.name = null;
    this.jsession_id = null;
  }
}

export default TSUser;
