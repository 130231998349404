import apiService from "./api/apiServices";
import localStorageService from "./localStorageService";
import logService from "./logService";
import sessionStorageService from "./sessionStorageService";
import userService from "./userService";
import utilityService from "./utilityService";

/**
 * This is just a container of all the available services in the app
 */
//------------------------------------------------
const appServices = {
  api: apiService,
  user: userService,
  localStorage: localStorageService,
  sessionStorage: sessionStorageService,
  utility: utilityService,
  log: logService,
};

export default appServices;
//------------------------------------------------
