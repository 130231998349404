import { AvailableApps, ElementSize, ElementStyle } from "../../constants/misc";
import { ModalsContext, UserContext } from "../../context/ContextProvider";
import React, { useContext, useState } from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import PageHeaderV2 from "../../components/PageHeaderV2";
import { Routes } from "../../constants/routes";
import { StorageProps } from "../../constants/storageProps";
import StyledAlert from "../../components/styled/StyledAlert";
import StyledCard from "../../components/styled/StyledCard";
import UserForm from "../../components/forms/UserForm";
import appServices from "../../services/appServices";
import defaults from "../../constants/defaults";
import { toast } from "react-toastify";
import useRefreshProfile from "../../hooks/useRefreshProfile";

const ProfilePage = (props) => {
  const userContext = useContext(UserContext);
  const modalsContext = useContext(ModalsContext);

  const [isLDLinked, setIsLDLinked] = useState(
    userContext.currentUser && userContext.currentUser.ld?.user_id
  );

  const [isTSLinked, setIsTSLinked] = useState(
    userContext.currentUser && userContext.currentUser.ts?.user_id
  );
  const [apiResponse, setApiResponse] = useState(defaults.apiResponseDefault);
  const [isBusy, setIsBusy] = useState(false);

  useRefreshProfile();

  const handleUnlinkAsync = (app) => {
    const modal = {
      show: true,
      title: "Unlink App",
      content: "Are you sure you want to unlink " + app + "?",
      type: ElementStyle.Danger,
      isFeatured: true,
      confirmationButton: "Unlink",
      cancelButton: "Cancel",
      onConfirm: async () => {
        await unlinkAppAsync(app);

        modalsContext.setShowConfirmationModal({
          ...modal,
          show: false,
        });
      },
    };
    modalsContext.setShowConfirmationModal(modal);
  };

  const unlinkAppAsync = async (app) => {
    switch (app) {
      case AvailableApps.TattleSystems:
        // Unlink Tattle Systems account
        const tsResponse = await appServices.api.auth.unlinkTS();
        if (!tsResponse.ok)
          toast.warning(appServices.log.extractErrorMessage(tsResponse));
        else setIsTSLinked(false);
        break;

      case AvailableApps.LiveDispatch:
        // Unlink Live Dispatch account
        const ldResponse = await appServices.api.auth.unlinkLD();
        if (!ldResponse.ok)
          toast.warning(appServices.log.extractErrorMessage(ldResponse));
        else setIsLDLinked(false);
        break;

      default:
        toast.warning("Unknown app.");
        break;
    }

    userContext.onCurrentUserChanged(
      appServices.sessionStorage.getProperty(StorageProps.currentUser)
    );
  };

  const updateUserAsync = async (data) => {
    setIsBusy(true);
    //-----------
    const response = await appServices.api.users.update(
      userContext.currentUser?.id,
      data
    );
    //-----------
    setIsBusy(false);
    // console.log(response);

    if (!response.ok) return;

    setApiResponse({ ...defaults.apiResponseDefault, isSuccess: true });
    toast.success("User updated successfully.");
    appServices.sessionStorage.setProperty(
      StorageProps.currentUser,
      response.data
    );
    userContext.onCurrentUserChanged(response.data);
  };

  return (
    <>
      <PageHeaderV2 title="Profile">
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs"></ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        {/* Left column */}
        <div className="col-xl-5 col-lg-8 mb-2">
          <StyledCard title="Details">
            <UserForm
              user={userContext.currentUser}
              inputSize={ElementSize.md}
              hasFloatingLabel={false}
              apiResponse={apiResponse}
              isBusy={isBusy}
              submitButtonText="Save Profile"
              withNotes
              onSubmit={async (data) => await updateUserAsync(data)}
            />
          </StyledCard>
        </div>

        {/* Right column */}
        <div className="col-xl-4 col-lg-6 col-md-12 mb-2">
          <StyledCard title="Linked Apps">
            <div className="row">
              <div className="col">
                {!isLDLinked && !isTSLinked && (
                  <StyledAlert
                    elementStyle={ElementStyle.Warning}
                    style={{ padding: ".5rem" }}>
                    You don't have any linked app(s) yet.
                    <br />
                    Go to the <Link to={Routes.dashboard}>dashboard</Link> to
                    link them.
                  </StyledAlert>
                )}
                {isTSLinked && (
                  <StyledCard
                    className="card-featured-left card-featured-primary mb-4"
                    cardBodyStyle={{ background: "#f5f5f5" }}>
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-default">
                          <img
                            className="external-app-icon"
                            src={`${process.env.PUBLIC_URL}/assets/img/tattle-systems-logo.png`}
                            height="150"
                            alt="Tattle Systems"
                          />
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h2 className="title">Tattle Systems</h2>
                          <div className="info">
                            Connected as:{" "}
                            <strong>
                              {userContext.currentUser?.ts?.username}
                            </strong>
                          </div>
                        </div>
                        <div className="summary-footer">
                          <button
                            className="btn btn-xs btn-danger text-uppercase"
                            onClick={() =>
                              handleUnlinkAsync(AvailableApps.TattleSystems)
                            }>
                            Unlink
                          </button>
                        </div>
                      </div>
                    </div>
                  </StyledCard>
                )}
                {isLDLinked && (
                  <StyledCard
                    className="card-featured-left card-featured-primary mb-4"
                    cardBodyStyle={{ background: "#f5f5f5" }}>
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-default">
                          <img
                            className="external-app-icon"
                            src={`${process.env.PUBLIC_URL}/assets/img/live-dispatch-logo.svg`}
                            height="150"
                            alt="Live Dispatch"
                          />
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h2 className="title">Live Dispatch</h2>
                          <div className="info">
                            Connected as:{" "}
                            <strong>
                              {userContext.currentUser?.ld?.username}
                            </strong>
                          </div>
                          <div className="info">
                            Branch:{" "}
                            <strong>
                              {userContext.currentUser?.ld?.client_name}
                            </strong>
                          </div>
                        </div>
                        <div className="summary-footer">
                          <button
                            className="btn btn-xs btn-danger text-uppercase"
                            href="#"
                            onClick={() =>
                              handleUnlinkAsync(AvailableApps.LiveDispatch)
                            }>
                            Unlink
                          </button>
                        </div>
                      </div>
                    </div>
                  </StyledCard>
                )}
              </div>
            </div>
          </StyledCard>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
