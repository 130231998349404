import { useContext, useEffect } from "react";

import { StatusCodes } from "http-status-codes";
import { StorageProps } from "../constants/storageProps";
import { UserContext } from "../context/ContextProvider";
import appServices from "../services/appServices";
import { toast } from "react-toastify";

const useRefreshProfile = () => {
  const userContext = useContext(UserContext);

  async function refreshProfileAsync() {
    try {
      const response = await appServices.api.auth.me();

      // update the currentUser on user context to notify listeners
      if (response?.status === StatusCodes.OK) {
        userContext.onCurrentUserChanged(
          appServices.sessionStorage.getProperty(StorageProps.currentUser)
        );
      }
    } catch (ex) {
      toast.error("Oops! Could not read your profile.");
    }
  }

  useEffect(() => {
    refreshProfileAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useRefreshProfile;
