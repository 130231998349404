import { Link, useLocation } from "react-router-dom";

import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { RoleIds } from "../../constants/misc";
import { Routes } from "../../constants/routes";
import { UserContext } from "../../context/ContextProvider";
import useImportScript from "../../hooks/useImportScript";
import appServices from "../../services/appServices";

const SidebarNav = (props) => {
  const location = useLocation();

  const userContext = useContext(UserContext);
  const isProduction = appServices.utility.isProduction();

  // const isLDLinked =
  //   userContext.currentUser && userContext.currentUser.ld?.user_id
  //     ? true
  //     : false;
  // const isTSLinked =
  //   userContext.currentUser && userContext.currentUser.ts?.user_id
  //     ? true
  //     : false;
  // const isBILinked = true;

  useImportScript(
    `${process.env.PUBLIC_URL}/assets/js/sidebar-menu.js`,
    isMobile
  );

  const handleDundasExternalLinkClick = async (event, isDemo = false) => {
    event.preventDefault();

    const toastInstance = toast.loading("Loading...");

    window.open(process.env.REACT_APP_DUNDAS_URL, "_blank");
    toast.dismiss(toastInstance);
  };

  return (
    <aside id="sidebar-left" className="sidebar-left">
      <div className="sidebar-header">
        <div className="sidebar-title">Menu</div>
        <div
          className="sidebar-toggle d-none d-md-block"
          data-toggle-class="sidebar-left-collapsed"
          data-target="html"
          data-fire-event="sidebar-left-toggle"
        >
          <i className="fas fa-bars" aria-label="Toggle sidebar"></i>
        </div>
      </div>

      <div className="nano">
        <div className="nano-content">
          <nav id="menu" className="nav-main" role="navigation">
            <ul className="nav nav-main">
              <li
                className={
                  location.pathname === Routes.dashboard ? "nav-active" : ""
                }
              >
                <Link
                  to={Routes.dashboard}
                  className="nav-link"
                  title="Applications"
                >
                  <i className="fa-solid fa-cubes" />
                  {/* <i className="fas fa-th-large"></i> */}
                  <span>Applications</span>
                </Link>
              </li>
              {(appServices.user.isAdmin() ||
                appServices.user.hasRole(
                  userContext.currentUser,
                  RoleIds.WhiteLabelPartnership
                )) && (
                <li
                  className={`nav-parent ${
                    location.pathname.startsWith(Routes.developerResources)
                      ? "nav-active"
                      : ""
                  }`}
                >
                  <Link
                    to="#"
                    className="nav-link"
                    title="Developer Resources"
                    onClick={(event) => event.preventDefault()}
                  >
                    <i className="fas fa-laptop-code" />
                    <span>Developer Resources</span>
                  </Link>
                  <ul className="nav nav-children">
                    <li
                      className={
                        location.pathname.startsWith(Routes.software)
                          ? "nav-parent nav-active"
                          : "nav-parent"
                      }
                    >
                      <Link
                        to="#"
                        className="nav-link"
                        title="Software"
                        onClick={(event) => event.preventDefault()}
                      >
                        <i className="bx bx-code-block" />
                        <span>Software</span>
                      </Link>
                      <ul className="nav nav-children">
                        <li
                          className={
                            location.pathname === Routes.apiSummary
                              ? "nav-active"
                              : ""
                          }
                        >
                          <a
                            href="https://documenter.getpostman.com/view/13438048/2s8ZDX437w#feb72339-15ff-4564-bc19-528e873def47"
                            className="nav-link"
                            rel="noreferrer"
                            target="_blank"
                            title="Tattle Systems API"
                          >
                            <span>Tattle Systems API</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        location.pathname.startsWith(Routes.hardware)
                          ? "nav-parent nav-active"
                          : "nav-parent"
                      }
                    >
                      <Link
                        to="#"
                        className="nav-link"
                        title="Hardware"
                        onClick={(event) => event.preventDefault()}
                      >
                        <i className="bx bxs-microchip" />
                        <span>Hardware</span>
                      </Link>
                      <ul className="nav nav-children">
                        {/* <li
                          className={
                            location.pathname === Routes.t3Lite
                              ? "nav-active"
                              : ""
                          }>
                          <Link
                            to={Routes.t3Lite}
                            className="nav-link"
                            title="T3 Lite Quick Start">
                            <span>T3 Lite Quick Start</span>
                          </Link>
                        </li> */}
                        <li
                          className={
                            location.pathname === Routes.gen3Gen4Specs
                              ? "nav-active"
                              : ""
                          }
                        >
                          <Link
                            to={Routes.gen3Gen4Specs}
                            className="nav-link"
                            title="Gen 3/Gen4 Specs"
                          >
                            <span>Gen 3/Gen4 Specs</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              )}
            </ul>

            {/* --------------------------------------- ADMIN ONLY - start --------------------------------------- */}
            {appServices.user.isAdmin() && (
              <>
                <span className="separator"></span>
                <ul className="nav nav-main">
                  <li
                    className={`nav-parent ${
                      location.pathname.startsWith(Routes.admin)
                        ? "nav-active"
                        : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="nav-link"
                      title="Admin"
                      onClick={(event) => event.preventDefault()}
                    >
                      <i className="fas fa-user-shield"></i>
                      <span>Admin</span>
                    </Link>
                    <ul className="nav nav-children">
                      {/* Users */}
                      <li
                        className={`nav-parent ${
                          location.pathname.startsWith(Routes.users)
                            ? "nav-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="nav-link"
                          title="Users"
                          onClick={(event) => event.preventDefault()}
                        >
                          <i className="fas fa-users"></i>
                          <span>Users</span>
                        </Link>
                        <ul className="nav nav-children">
                          <li
                            className={
                              `${location.pathname}${location.search}` ===
                              Routes.users
                                ? "nav-active"
                                : ""
                            }
                          >
                            <Link
                              to={Routes.users}
                              className="nav-link"
                              title="All Users"
                            >
                              <span>All Users</span>
                            </Link>
                          </li>
                          <li
                            className={
                              `${location.pathname}${location.search}` ===
                              Routes.usersBlocked
                                ? "nav-active"
                                : ""
                            }
                          >
                            <Link
                              to={Routes.usersBlocked}
                              className="nav-link"
                              title="Blocked Users"
                            >
                              <span>Blocked Users</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Tools */}
                      <li
                        className={`nav-parent ${
                          location.pathname.startsWith(Routes.tools)
                            ? "nav-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="nav-link"
                          title="Tools"
                          onClick={(event) => event.preventDefault()}
                        >
                          <i className="fas fa-tools"></i>
                          <span>Tools</span>
                        </Link>
                        <ul className="nav nav-children">
                          <li>
                            <Link
                              to="/#"
                              className="nav-link"
                              title={
                                isProduction
                                  ? "NSBI Designer (new tab)"
                                  : "NSBI DEMO Designer (new tab)"
                              }
                              onClick={handleDundasExternalLinkClick}
                            >
                              {/* <i className="fas fa-chart-pie"></i> */}
                              {isProduction ? (
                                <span>NSBI Designer</span>
                              ) : (
                                <span>
                                  NSBI <strong>DEMO</strong> Designer
                                </span>
                              )}
                              <i
                                className="fas fa-external-link-alt"
                                style={{ float: "right" }}
                              ></i>
                            </Link>
                          </li>
                          {/* <li
                            className={
                              location.pathname === Routes.toolsBiDesigner
                                ? "nav-active"
                                : ""
                            }>
                            <Link
                              to={Routes.toolsBiDesigner}
                              className="nav-link"
                              title={
                                isProduction
                                  ? "NS BI Designer"
                                  : "NS BI DEMO Designer"
                              }>
                              {isProduction ? (
                                <span>NS BI Designer</span>
                              ) : (
                                <span>
                                  NS BI <strong>DEMO</strong> Designer
                                </span>
                              )}
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                      {/* DEV Tools */}
                      <li
                        className={`nav-parent ${
                          location.pathname.startsWith(Routes.tools)
                            ? "nav-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="nav-link"
                          title="Dev Tools"
                          onClick={(event) => event.preventDefault()}
                        >
                          <i className="fa-solid fa-code"></i>
                          <span>Dev Tools</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            )}
            {/* --------------------------------------- ADMIN ONLY - end --------------------------------------- */}

            {/* <li
                className={
                  location.pathname === Routes.reports ? "nav-active" : ""
                }>
                <Link className="nav-link" to={Routes.reports}>
                  <i className="fas fa-chart-pie"></i>
                  <span>Reports</span>
                </Link>
              </li> */}
            {/* <li
                className={
                  location.pathname === Routes.analysis ? "nav-active" : ""
                }>
                <Link className="nav-link" to={Routes.analysis}>
                  <i className="bx bx-code-block"></i>
                  <span>Analysis/AI</span>
                </Link>
              </li> */}
          </nav>
        </div>
      </div>
      <div
        className="sidebar-widget"
        style={{
          display: "block",
          position: "fixed",
          bottom: 0,
          margin: 0,
          width: "inherit",
        }}
      >
        <div className="widget-header text-center">
          <span className="text-muted">v{process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </aside>
  );
};

export default SidebarNav;
