import React, { useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { MetaDataKeys, RoleIds } from "../constants/misc";

import Pusher from "pusher-js";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import LayoutSignedIn from "../components/LayoutSignedIn";
import LayoutSignedOut from "../components/LayoutSignedOut";
import PrivateRoute from "../components/PrivateRoute";
import { Routes } from "../constants/routes";
import { UserContext } from "../context/ContextProvider";
import useRefreshSystemSettings from "../hooks/useRefreshSystemSettings";
import AnalysisPage from "../pages/AnalysisPage";
import DashboardPage from "../pages/DashboardPage";
import TestPage from "../pages/TestPage";
import UsersCreatePage from "../pages/admin/users/UsersCreatePage";
import UsersDundasReportsPage from "../pages/admin/users/UsersDundasReportsPage";
import UsersEditPage from "../pages/admin/users/UsersEditPage";
import UsersPage from "../pages/admin/users/UsersPage";
import ExternalLoginPage from "../pages/authentication/ExternalLoginPage";
import ForgotPasswordPage from "../pages/authentication/ForgotPasswordPage";
import LoginPage from "../pages/authentication/LoginPage";
import ResetPasswordPage from "../pages/authentication/ResetPasswordPage";
import SignUpPage from "../pages/authentication/SignUpPage";
import DeveloperResourcesPage from "../pages/developerResources/DeveloperResourcesPage";
import DocumentPage from "../pages/developerResources/DocumentPage";
import Forbidden403Page from "../pages/errors/Forbidden403Page";
import NotFound404Page from "../pages/errors/NotFound404Page";
import Unauthorized401Page from "../pages/errors/Unauthorized401Page";
import ProfilePage from "../pages/profile/ProfilePage";
import SettingsPage from "../pages/profile/SettingsPage";
import ReportDetailsPage from "../pages/reports/ReportDetailsPage";
import ReportsPage from "../pages/reports/ReportsPage";
import appServices from "../services/appServices";

const NavigationProvider = (props) => {
  const isLoggedIn = appServices.user.isLoggedIn();

  const { currentUser, onCurrentUserChanged } = useContext(UserContext);
  useRefreshSystemSettings();

  useEffect(() => {
    if (!currentUser) return;

    if (!appServices.utility.isProduction())
      console.log("Pusher", {
        id: process.env.REACT_APP_PUSHER_APP_ID,
        key: process.env.REACT_APP_PUSHER_APP_KEY,
        secret: process.env.REACT_APP_PUSHER_APP_SECRET,
      });

    Pusher.logToConsole = !appServices.utility.isProduction();

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_API_BASE_URL}/broadcasting/auth`,
      },
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
      forceTLS: true,
    });

    const channel = pusher.subscribe(`login-notification.${currentUser?.id}`);

    channel.bind("pusher:subscription_succeeded", function (data) {
      if (!appServices.utility.isProduction())
        console.log("subscribed", channel);
    });

    channel.bind("App\\Events\\LoginNotificationEvent", function (data) {
      // console.log("login-notification data", data);
      // Code that runs when channel listens to a new message
      handleLoginNotificationReceived();
    });

    return () => {
      pusher.unsubscribe(`login-notification.${currentUser?.id}`);
      // console.log("unsubscribed from channel");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginNotificationReceived = async () => {
    const response = await appServices.api.auth.me();
    if (response.ok) {
      onCurrentUserChanged(response.data);
    }

    const singleSessionLimit = !!appServices.user.getMetaData(
      currentUser,
      MetaDataKeys.Settings_SingleSessionLimit
    );

    if (singleSessionLimit) await handleLogOut();
  };

  const handleLogOut = async () => {
    try {
      await appServices.api.auth.logOut();
    } finally {
      toast.warning(
        "This session expired because you have logged in from another device.",
        {
          autoClose: 5000,
          position: "top-center",
          theme: "colored",
        }
      );
      setTimeout(() => {
        window.location = Routes.login;
      }, 5000);
    }
  };

  return (
    <Router forceRefresh={isMobile}>
      <Switch>
        <Route exact path="/dashboard">
          <LayoutSignedIn>
            <NotFound404Page />
          </LayoutSignedIn>
        </Route>
        <PrivateRoute exact path={Routes.test}>
          <TestPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={Routes.developerResources}
          roles={[RoleIds.Admin, RoleIds.WhiteLabelPartnership]}
        >
          <DeveloperResourcesPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={Routes.apiSummary}
          roles={[RoleIds.Admin, RoleIds.WhiteLabelPartnership]}
        >
          <DocumentPage
            backgroundColor="white"
            src="https://documenter.getpostman.com/view/13438048/2s8ZDX437w#feb72339-15ff-4564-bc19-528e873def47"
            // src={`${process.env.PUBLIC_URL}/assets/html/Tattle-Systems-API-2022-aug-23.html`}
            title="Tattle Systems API"
          />
        </PrivateRoute>
        {/* <PrivateRoute
          exact
          path={Routes.t3Lite}
          roles={[RoleIds.Admin, RoleIds.WhiteLabelPartnership]}>
          <DocumentPage
            src={`${process.env.PUBLIC_URL}/assets/html/T3Lite/4abafb8e-a3e8-11ec-a980-0cc47a792c0a_id_4abafb8e-a3e8-11ec-a980-0cc47a792c0a.html`}
            title="T3 Lite Quick Start"
          />
        </PrivateRoute> */}
        <PrivateRoute
          exact
          path={Routes.gen3Gen4Specs}
          roles={[RoleIds.Admin, RoleIds.WhiteLabelPartnership]}
        >
          <DocumentPage
            src={`${process.env.PUBLIC_URL}/assets/html/TATTLE_G3_G4_pcba.pdf`}
            title="Gen 3/Gen4 Specs"
          />
        </PrivateRoute>
        {/* ----------- REPORTS - start ----------- */}
        <PrivateRoute exact path={Routes.reports} hasToolbar>
          <ReportsPage />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.reports_folder} hasToolbar>
          <ReportsPage />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.report_details}>
          <ReportDetailsPage />
        </PrivateRoute>
        {/* ----------- REPORTS - end ----------- */}
        <PrivateRoute exact path={Routes.analysis}>
          <AnalysisPage />
        </PrivateRoute>
        {/* ----------- USERS - start ----------- */}
        <PrivateRoute
          exact
          path={Routes.users}
          hasToolbar
          roles={[RoleIds.Admin]}
        >
          <UsersPage />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.usersCreate} roles={[RoleIds.Admin]}>
          <UsersCreatePage />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.usersEdit} roles={[RoleIds.Admin]}>
          <UsersEditPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={Routes.usersDundasReports}
          hasToolbar
          roles={[RoleIds.Admin]}
        >
          <UsersDundasReportsPage />
        </PrivateRoute>
        {/* ----------- USERS - end ----------- */}
        <PrivateRoute exact path={Routes.profile}>
          <ProfilePage />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.settings}>
          <SettingsPage />
        </PrivateRoute>
        {/* ----------- AUTH - start ----------- */}
        <Route
          exact
          path={Routes.login}
          render={(props) => {
            return isLoggedIn !== true ||
              props.location?.state?.allow === true ? (
              <LoginPage {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: Routes.dashboard,
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
        <Route exact path={Routes.externalLogin}>
          <LayoutSignedOut>
            <ExternalLoginPage />
          </LayoutSignedOut>
        </Route>
        <Route
          exact
          path={Routes.resetPassword}
          render={(props) => {
            return isLoggedIn !== true ? (
              <ResetPasswordPage {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: Routes.dashboard,
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
        <Route
          exact
          path={Routes.forgotPassword}
          render={(props) => {
            return isLoggedIn !== true ? (
              <ForgotPasswordPage {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: Routes.dashboard,
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
        <Route
          exact
          path={Routes.register}
          render={(props) => {
            return isLoggedIn !== true ? (
              <SignUpPage {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: Routes.dashboard,
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
        {/* ----------- AUTH - end ----------- */}
        <PrivateRoute exact path={Routes.dashboard}>
          <DashboardPage currentUser={currentUser} />
        </PrivateRoute>
        <Route exact path={Routes.forbidden}>
          <LayoutSignedIn>
            <Forbidden403Page />
          </LayoutSignedIn>
        </Route>
        <Route exact path={Routes.unauthorized}>
          <LayoutSignedIn>
            <Unauthorized401Page />
          </LayoutSignedIn>
        </Route>
        <Route path="*">
          {isLoggedIn !== true ? (
            <LayoutSignedOut className="">
              <NotFound404Page />
            </LayoutSignedOut>
          ) : (
            <LayoutSignedIn>
              <NotFound404Page />
            </LayoutSignedIn>
          )}
        </Route>
        <Redirect to={Routes.notFound} />
      </Switch>
    </Router>
  );
};

export default NavigationProvider;
