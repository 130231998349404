import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "../constants/routes";
import HeaderNav from "./nav/HeaderNav";
import SidebarNav from "./nav/SidebarNav";

const LayoutSignedIn = (props) => {
  const contentBodyClasses = props.hasToolbar
    ? "content-body content-body-modern has-toolbar"
    : "content-body content-body-modern";

  const reportDetailsPageMatch = useRouteMatch(Routes.report_details);

  if (reportDetailsPageMatch) return props.children;

  return (
    <section className="body">
      <HeaderNav></HeaderNav>
      <div className="inner-wrapper">
        <SidebarNav />
        <section role="main" className={contentBodyClasses}>
          {props.children}
        </section>
      </div>
    </section>
  );
};

export default LayoutSignedIn;
