import PropTypes from "prop-types";
import React from "react";

const StyledCard = ({
  title,
  TitleComponent,
  subTitle,
  elementStyle,
  isFeatured,
  cardBodyStyle,
  children,
  showCloseButton,
  showDropdownButton,
  onClose,
  ...props
}) => {
  let cardClass = isFeatured ? "card-featured" : "";
  if (elementStyle) {
    cardClass =
      cardClass +
      (isFeatured ? " card-featured-" + elementStyle : " card-" + elementStyle);
  }

  const handleClose = (event) => {
    event.preventDefault();
    if (onClose) onClose();
  };

  return (
    <section
      className={`card ${cardClass} ${props.className ?? ""}`}
      {...props}>
      {(title || subTitle || TitleComponent) && (
        <header className="card-header">
          <div className="card-actions">
            {showDropdownButton === true && (
              <button
                className="btn btn-link card-action card-action-toggle no-padding"
                data-card-toggle=""></button>
            )}
            {showCloseButton === true && (
              <button
                className="btn btn-link card-action card-action-dismiss no-padding"
                data-card-dismiss=""
                onClick={handleClose}></button>
            )}
          </div>

          {!!title ? (
            <h2 className="card-title">{title}</h2>
          ) : (
            TitleComponent ?? null
          )}
          {!!subTitle && <p className="card-subtitle">{subTitle}</p>}
        </header>
      )}
      <div className="card-body" style={cardBodyStyle}>
        {children}
      </div>
    </section>
  );
};

StyledCard.propTypes = {
  isFeatured: PropTypes.bool,
};

export default StyledCard;
