import { Link } from "react-router-dom";
import React from "react";
import { Routes } from "../constants/routes";

const ReportFolder = ({ folder, onClick = () => {}, ...otherProps }) => {
  const target = !!folder.id
    ? Routes.reports_folder.replace(":folderId", folder.id)
    : Routes.reports_folder.replace(":folderId", folder.name);

  return (
    <div className="d-block center">
      <Link to={target}>
        <img
          alt="Folder"
          className="d-block"
          height={200}
          onClick={() => onClick(folder)}
          src={`${process.env.PUBLIC_URL}/assets/img/folder-icon.svg`}
          width={200}
        />
      </Link>
      <h3>{folder?.name}</h3>
    </div>
  );
};

export default ReportFolder;
