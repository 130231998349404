import { NodePositions } from "../constants/misc";
import appServices from "../services/appServices";
import { useEffect } from "react";

const useImportScript = (
  resourceUrl,
  condition = true,
  position = NodePositions.Append
) => {
  useEffect(() => {
    if (condition) {
      const script = appServices.utility.appendScript(resourceUrl, position);
      return () => {
        appServices.utility.removeScript(script);
      };
    }
  }, [resourceUrl, condition, position]);
};

export default useImportScript;
