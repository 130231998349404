export const ApiRoutes = {
  // Log in
  login: `api/${process.env.REACT_APP_API_VERSION}/auth/login`,
  externalLogin: `api/${process.env.REACT_APP_API_VERSION}/auth/external-login?token={token}`,
  signUp: `api/${process.env.REACT_APP_API_VERSION}/auth/signup`,
  // Log out
  logOut: `api/${process.env.REACT_APP_API_VERSION}/auth/logout`,

  // Password
  forgotPassword: `api/${process.env.REACT_APP_API_VERSION}/passwords/forgot`,
  resetPassword: `api/${process.env.REACT_APP_API_VERSION}/passwords/reset`,

  // Current portal user
  me: `api/${process.env.REACT_APP_API_VERSION}/auth/me`,

  // Link
  linkLD: `api/${process.env.REACT_APP_API_VERSION}/auth/link-ld`,
  unlinkLD: `api/${process.env.REACT_APP_API_VERSION}/auth/unlink-ld`,
  linkTS: `api/${process.env.REACT_APP_API_VERSION}/auth/link-ts`,
  unlinkTS: `api/${process.env.REACT_APP_API_VERSION}/auth/unlink-ts`,

  getTattleUser: `api/${process.env.REACT_APP_API_VERSION}/auth/tattle-user`,

  // MetaData
  storeUserMetaData: `api/${process.env.REACT_APP_API_VERSION}/meta/user`,

  // Dundas
  dundasDeleteAccount: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/account/{userId}`,
  dundasGetAccount: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/account/{id}`,
  dundasGetAccountByName: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/account?name={name}`,
  dundasGetAllProjects: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/project/all`,
  dundasGetLogOnToken: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/log-on/token`,
  dundasCreateAccount: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/account`,
  dundasAttachProjectToAccount: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/account/project`,
  dundasGetProjectItems: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/project/project-items`,
  dundasGetUsersProjectItems: `api/${process.env.REACT_APP_API_VERSION}/dundas-bi/project/users-project-items`,

  // Report Folders
  reportFolders: `api/${process.env.REACT_APP_API_VERSION}/bi/reports/folders`,
  reportFoldersCreate: `api/${process.env.REACT_APP_API_VERSION}/bi/reports/folders`,
  reportFoldersDelete: `api/${process.env.REACT_APP_API_VERSION}/bi/reports/folders/{id}`,
  reportFoldersUpdate: `api/${process.env.REACT_APP_API_VERSION}/bi/reports/folders/{id}`,
  reportSetFolder: `api/${process.env.REACT_APP_API_VERSION}/bi/reports/{id}/set-folder`,

  // Files
  filesDelete: `api/${process.env.REACT_APP_API_VERSION}/files/{id}`,
  filesUploadLogo: `api/${process.env.REACT_APP_API_VERSION}/files/logo`,

  // Users
  usersAll: `api/${process.env.REACT_APP_API_VERSION}/users`,
  usersGet: `api/${process.env.REACT_APP_API_VERSION}/users/{id}`,
  usersCreate: `api/${process.env.REACT_APP_API_VERSION}/users`,
  usersEdit: `api/${process.env.REACT_APP_API_VERSION}/users/{id}`,
  usersDelete: `api/${process.env.REACT_APP_API_VERSION}/users/{id}`,
  usersRestore: `api/${process.env.REACT_APP_API_VERSION}/users/{id}/restore`,
  usersReports: `api/${process.env.REACT_APP_API_VERSION}/users/{id}/reports`,

  systemSettingsAll: `api/${process.env.REACT_APP_API_VERSION}/system-settings`,
  systemSettingsSaveMany: `api/${process.env.REACT_APP_API_VERSION}/system-settings/save-many`,

  // Two-Factor
  twoFAVerifyCode: `api/${process.env.REACT_APP_API_VERSION}/two-fa/verify`,

  // Roles
  rolesAll: `api/${process.env.REACT_APP_API_VERSION}/roles`,
  rolesAvailable: `api/${process.env.REACT_APP_API_VERSION}/roles/available`,

  // UserLogs
  userLogsIncreaseNSBIClicks: `api/${process.env.REACT_APP_API_VERSION}/user-logs/increase-nsbi-clicks`,
  userLogsIncreaseReportClicks: `api/${process.env.REACT_APP_API_VERSION}/user-logs/increase-report-clicks?report_id={report_id}`,
};
