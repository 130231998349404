import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import PageHeaderV2 from "../../components/PageHeaderV2";
import React from "react";
import { Routes } from "../../constants/routes";

const DocumentPage = ({ backgroundColor, src, title, ...otherProps }) => {
  const iframe = () => {
    return {
      __html:
        '<iframe src="' +
        src +
        '" style="width: 100%; height:calc(100vh - 137px);"></iframe>',
    };
  };

  return (
    <>
      <PageHeaderV2 title={title}>
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            <li>
              <Link to={Routes.t3Lite}>
                <i className="fas fa-file-alt"></i> {title}
              </Link>
            </li>
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div
        dangerouslySetInnerHTML={iframe()}
        style={{ backgroundColor: backgroundColor ?? "unset" }}
      />
    </>
  );
};

export default DocumentPage;
