import LoginForm from "../../components/forms/LoginForm";
import { Modal } from "react-bootstrap";
import React from "react";

const LinkAppDialog = (props) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Modal
      dialogClassName="link-app-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...props}>
      <LoginForm
        isModal={true}
        app={props.app}
        handleClose={props.onHide}
        onSuccess={handleReload}
      />
    </Modal>
  );
};

export default LinkAppDialog;
