import { StorageProps } from "../constants/storageProps";
import appServices from "../services/appServices";
import { useEffect } from "react";

const useClearCacheIfNeeded = () => {
  useEffect(() => {
    const currentBuildNumber =
      appServices.localStorage.getProperty(StorageProps.buildNumber) ?? 0;
    const latestBuildNumber = parseInt(process.env.REACT_APP_BUILD);

    if (currentBuildNumber < latestBuildNumber) {
      appServices.utility.clearCache();
    }

    return () => {};
  }, []);
};

export default useClearCacheIfNeeded;
