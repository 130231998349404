import { ElementStyle } from "../../constants/misc";
import React from "react";

const StyledSwitch = ({
  label,
  className,
  isDisabled,
  isChecked,
  onChange,
  elementStyle = ElementStyle.Default,
  ...props
}) => {
  return (
    <>
      <label
        className={`ios7-switch ios7-switch-${elementStyle} ${className}`}
        {...props}>
        <input
          type="checkbox"
          disabled={isDisabled ? "disabled" : null}
          checked={isChecked}
          onChange={onChange}
        />
        <span></span>
      </label>{" "}
      <span>{label}</span>
    </>
  );
};

export default StyledSwitch;
