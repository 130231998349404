import Breadcrumbs from "../components/Breadcrumbs";
import PageHeaderV2 from "../components/PageHeaderV2";
import React from "react";

const AnalysisPage = () => {
  return (
    <>
      <PageHeaderV2 title="Analysis/AI">
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            {/* <li>
                <Link to={Routes.dashboard}>
                  <i className="fas fa-th-large"></i>
                </Link>
              </li> */}
            {/* <li>
              <span>Analysis/AI</span>
            </li> */}
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        <div className="col-sm-12 mb-3">
          <iframe
            src="https://northstarsystems.on.gooddata.com/dashboards/embedded/#/project/abv25y59jv8ky9hvz8k7mrrdn5gypix6/dashboard/aacZ5thZaF3l?showNavigation=false"
            style={{ width: "100%", height: "1500px" }}
            frameborder="0"
            title="Good Data"></iframe>
        </div>
      </div>
    </>
  );
};

export default AnalysisPage;
