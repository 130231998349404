import { Form } from "react-bootstrap";
import React from "react";

const AppFormLabel = ({ label, required, visible = true, ...otherProps }) => {
  if (!visible) return null;

  return (
    <Form.Label {...otherProps}>
      {label} {required && <span className="required">*</span>}
    </Form.Label>
  );
};

export default AppFormLabel;
