import { Link } from "react-router-dom";
import PageHeaderV2 from "../../components/PageHeaderV2";
import React from "react";
import { Routes } from "../../constants/routes";

const Unauthorized401Page = (props) => {
  return (
    <>
      {props.hasHeader && <PageHeaderV2 title="Unauthorized" />}

      <section className="body-error error-inside">
        <div className="center-error">
          <div className="row">
            <div className="col-lg-8">
              <div className="main-error mb-3">
                <h2 className="error-code text-dark text-center font-weight-semibold m-0">
                  401 <i className="fas fa-file"></i>
                </h2>
                <p className="error-explanation text-center">
                  We're sorry, but you are not authorized to view this page.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <h4 className="text">Here are some useful links</h4>
              <ul className="nav nav-list flex-column primary">
                <li className="nav-item">
                  <Link className="nav-link" to={Routes.dashboard}>
                    <i className="fas fa-caret-right text-dark"></i> Dashboard
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Unauthorized401Page;
