import React, { useContext, useState } from "react";

import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { MetaDataKeys } from "../../constants/misc";
import { Routes } from "../../constants/routes";
import { StorageProps } from "../../constants/storageProps";
import { UserContext } from "../../context/ContextProvider";
import appServices from "../../services/appServices";
import ChangeLogoDialog from "../modals/ChangeLogoDialog";

const HeaderNav = (props) => {
  const { currentUser, onCurrentUserChanged } = useContext(UserContext);
  const [logoDialog, setLogoDialog] = useState({
    show: false,
    title: "Change Logo",
    user: null,
  });
  const mobileView = appServices.sessionStorage.getProperty(
    StorageProps.mobileView
  );
  const logoImgSrc = mobileView ? "NSBI_logo_v3.png" : "nss-logo.svg";

  const handleChangeLogoClick = (event) => {
    event.preventDefault();

    setLogoDialog({ ...logoDialog, user: currentUser, show: true });
  };

  const handleLogoUpdate = (userId, logo) => {
    appServices.sessionStorage.setProperty(StorageProps.currentUser, {
      ...currentUser,
      logo: logo,
    });
    onCurrentUserChanged(
      appServices.sessionStorage.getProperty(StorageProps.currentUser)
    );
  };

  const handleLogOut = async () => {
    try {
      await appServices.api.auth.logOut();
    } finally {
      window.location = Routes.login;
    }
  };

  return (
    // <!-- start: header -->
    <>
      <header className={"header" + (mobileView ? " header-mobile" : "")}>
        <div
          className={
            "logo-container" + (mobileView ? " logo-container-mobile" : "")
          }
        >
          <Link
            to={mobileView ? Routes.reports : Routes.dashboard}
            className="logo"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/${logoImgSrc}`}
              // width="115"
              height="35"
              alt="Portal"
            />
          </Link>

          {!mobileView && (
            <div
              className="d-md-none toggle-sidebar-left"
              data-toggle-class="sidebar-left-opened"
              data-target="html"
              data-fire-event="sidebar-left-opened"
            >
              <i className="fas fa-bars" aria-label="Toggle sidebar"></i>
            </div>
          )}
        </div>

        <div
          className={
            "header-right" + (mobileView ? " header-right-mobile" : "")
          }
        >
          <span className="separator"></span>

          {/* --------- User Box --------- */}
          <div id="userbox" className="userbox">
            <Link to="#" data-bs-toggle="dropdown">
              <figure className="profile-picture">
                <img
                  // src={`${process.env.PUBLIC_URL}/theme/img/logged-user.png`}
                  src={
                    currentUser.logo?.thumbnail_url ??
                    `${process.env.PUBLIC_URL}/theme/img/logged-user.png`
                  }
                  alt="Joseph Doe"
                  className="rounded-circle"
                  data-lock-picture={`${process.env.PUBLIC_URL}/theme/img/logged-user.png`}
                />
              </figure>
              <div
                className="profile-info"
                data-lock-name={currentUser.name}
                data-lock-email={currentUser.email}
              >
                <span className="name">
                  {appServices.user.getMetaData(
                    currentUser,
                    MetaDataKeys.CompanyName
                  )}
                </span>
                <span className="role">{currentUser.email}</span>
              </div>

              <i className="fa custom-caret"></i>
            </Link>

            <div className="dropdown-menu">
              <ul className="list-unstyled mb-2">
                <li className="divider"></li>
                <li>
                  <Link role="menuitem" tabIndex="-1" to={Routes.profile}>
                    <i className="bx bx-user-circle"></i> My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    role="menuitem"
                    tabIndex="-1"
                    to="#"
                    onClick={handleChangeLogoClick}
                  >
                    <i className="bx bx-id-card"></i> Change Logo
                  </Link>
                </li>
                {/* {appServices.user.isAdmin() && ( */}
                <li>
                  <Link role="menuitem" tabIndex="-1" to={Routes.settings}>
                    <i className="bx bx-cog"></i> Settings
                  </Link>
                </li>
                {/* )} */}
                {/* <li>
                <Link
                role="menuitem"
                tabIndex="-1"
                to="#"
                data-lock-screen="true">
                <i className="bx bx-lock"></i> Lock Screen
                </Link>
              </li> */}
                <li className="divider"></li>
                <li>
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={handleLogOut}
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <i className="bx bx-power-off"></i> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- end: search & user box --> */}
      </header>

      {ReactDOM.createPortal(
        <ChangeLogoDialog
          user={logoDialog.user}
          show={logoDialog.show}
          onHide={() => {
            setLogoDialog({ ...logoDialog, show: false });
          }}
          onSuccess={(logo) => {
            handleLogoUpdate(currentUser.id, logo);
          }}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default HeaderNav;
