import React from "react";

const FormFieldContainer = ({ children, className, ...otherProps }) => {
  return (
    <div className={`mb-3 ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default FormFieldContainer;
