import "react-toastify/dist/ReactToastify.css";

import {
  AppContext,
  ModalsContext,
  UserContext,
} from "./context/ContextProvider";
import React, { useState } from "react";

import { AvailableApps } from "./constants/misc";
import ConfirmationDialog from "./components/modals/ConfirmationDialog";
import LinkAppDialog from "./components/modals/LinkAppDialog";
import ModalDialog from "./components/modals/ModalDialog";
import NavigationProvider from "./navigation/NavigationProvider";
import ReactDOM from "react-dom";
import { StorageProps } from "./constants/storageProps";
import { ToastContainer } from "react-toastify";
import appServices from "./services/appServices";
import useClearCacheIfNeeded from "./hooks/useClearCacheIfNeeded";

function App(props) {
  window.Pusher = require("pusher-js");

  const [showLDLoginModal, setShowLDLoginModal] = useState(false);
  const [showTSLoginModal, setShowTSLoginModal] = useState(false);
  const [systemSettings, setSystemSettings] = useState([]);

  useClearCacheIfNeeded();

  const [showModal, setShowModal] = useState({
    body: null,
    buttons: null,
    content: null,
    size: "md",
    title: null,
    visible: false,
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState({
    show: false,
    title: "Confirm",
    content: null,
    type: null,
    isFeatured: null,
    confirmationButton: "Yes",
    cancelButton: "Cancel",
    isBusy: false,
    onConfirm: () => {
      setShowConfirmationModal({
        ...showConfirmationModal,
        show: false,
      });
    },
  });
  const [currentUser, setCurrentUser] = useState(
    appServices.sessionStorage.getProperty(StorageProps.currentUser)
  );

  const appContextVale = { systemSettings, setSystemSettings };

  const modalsContextValue = {
    showLDLoginModal,
    setShowLDLoginModal,
    showTSLoginModal,
    setShowTSLoginModal,
    showConfirmationModal,
    setShowConfirmationModal,
    // Generic dialog with buttons
    showModal: (title, body, buttons, content, size = "md") =>
      setShowModal({
        body,
        buttons,
        content,
        size,
        title,
        visible: true,
      }),
    closeModal: () =>
      setShowModal({
        body: null,
        buttons: null,
        content: null,
        title: null,
        visible: false,
      }),
  };

  const userContextValue = {
    currentUser,
    onCurrentUserChanged: setCurrentUser,
  };

  return (
    <>
      <AppContext.Provider value={appContextVale}>
        <UserContext.Provider value={userContextValue}>
          <ModalsContext.Provider value={modalsContextValue}>
            <NavigationProvider />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {ReactDOM.createPortal(
              <ModalDialog
                body={showModal.body}
                buttons={showModal.buttons}
                content={showModal.content}
                show={showModal.visible}
                size={showModal.size}
                title={showModal.title}
              />,
              document.getElementById("overlay-root")
            )}
          </ModalsContext.Provider>
        </UserContext.Provider>
      </AppContext.Provider>

      {/* ------------------- PORTALS - start ------------------- */}
      {ReactDOM.createPortal(
        <LinkAppDialog
          app={AvailableApps.LiveDispatch}
          show={showLDLoginModal}
          onHide={() => setShowLDLoginModal(false)}
        />,
        document.getElementById("overlay-root")
      )}
      {ReactDOM.createPortal(
        <LinkAppDialog
          app={AvailableApps.TattleSystems}
          show={showTSLoginModal}
          onHide={() => setShowTSLoginModal(false)}
        />,
        document.getElementById("overlay-root")
      )}
      {ReactDOM.createPortal(
        <ConfirmationDialog
          title={showConfirmationModal.title}
          elementStyle={showConfirmationModal.type}
          isFeatured={showConfirmationModal.isFeatured}
          confirmButtonText={showConfirmationModal.confirmationButton}
          cancelButtonText={showConfirmationModal.cancelButton}
          isBusy={showConfirmationModal?.isBusy}
          show={showConfirmationModal?.show}
          onHide={() =>
            setShowConfirmationModal({
              ...showConfirmationModal,
              show: false,
            })
          }
          onConfirm={showConfirmationModal.onConfirm}>
          {showConfirmationModal.content}
        </ConfirmationDialog>,
        document.getElementById("overlay-root")
      )}
      {/* ------------------- PORTALS - end ------------------- */}
    </>
  );
}

export default App;
