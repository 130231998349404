import { LoadingIconTypes } from "../constants/misc";
import React from "react";

const CustomSpinner = ({ isActive, type, ...props }) => {
  type = type ?? LoadingIconTypes.Spinner;

  return (
    <div className={`overlay-spinner-container ${isActive ? "" : "hidden"}`}>
      <img
        src={type}
        className={`overlay-spinner ${props.className}`}
        alt="loading..."
        style={{
          height: "100px",
          width: "100px",
          ...props.style,
        }}
      />
    </div>
  );
};

export default CustomSpinner;
