import { ApiRoutes } from "../../constants/apiRoutes";
import { StatusCodes } from "http-status-codes";
import httpService from "../httpService";
import userService from "../userService";

const verifyCode = async (code, secret) => {
  const payload = {
    code: code,
    secret: secret,
  };

  const response = await httpService.post(ApiRoutes.twoFAVerifyCode, payload);
  if (response.status === StatusCodes.OK) {
    userService.setAuthProperties(response.data);
  }

  return response;
};

//------------------------------------------------
const twoFactorApiService = {
  verifyCode,
};

export default twoFactorApiService;
//------------------------------------------------
