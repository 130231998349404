import CopyrightFooter from "../../components/CopyrightFooter";
import { ElementSize } from "../../constants/misc";
import { Link } from "react-router-dom";
import React from "react";
import { Routes } from "../../constants/routes";
import { StatusCodes } from "http-status-codes";
import UserForm from "../../components/forms/UserForm";
import appServices from "../../services/appServices";
import defaults from "../../constants/defaults";
import { toast } from "react-toastify";
import { useState } from "react";

// messages: {
//   'string.alphanum': '{{#label}} must only contain alpha-numeric characters',
//   'string.base': '{{#label}} must be a string',
//   'string.base64': '{{#label}} must be a valid base64 string',
//   'string.creditCard': '{{#label}} must be a credit card',
//   'string.dataUri': '{{#label}} must be a valid dataUri string',
//   'string.domain': '{{#label}} must contain a valid domain name',
//   'string.email': '{{#label}} must be a valid email',
//   'string.empty': '{{#label}} is not allowed to be empty',
//   'string.guid': '{{#label}} must be a valid GUID',
//   'string.hex': '{{#label}} must only contain hexadecimal characters',
//   'string.hexAlign': '{{#label}} hex decoded representation must be byte aligned',
//   'string.hostname': '{{#label}} must be a valid hostname',
//   'string.ip': '{{#label}} must be a valid ip address with a {{#cidr}} CIDR',
//   'string.ipVersion': '{{#label}} must be a valid ip address of one of the following versions {{#version}} with a {{#cidr}} CIDR',
//   'string.isoDate': '{{#label}} must be in iso format',
//   'string.isoDuration': '{{#label}} must be a valid ISO 8601 duration',
//   'string.length': '{{#label}} length must be {{#limit}} characters long',
//   'string.lowercase': '{{#label}} must only contain lowercase characters',
//   'string.max': '{{#label}} length must be less than or equal to {{#limit}} characters long',
//   'string.min': '{{#label}} length must be at least {{#limit}} characters long',
//   'string.normalize': '{{#label}} must be unicode normalized in the {{#form}} form',
//   'string.token': '{{#label}} must only contain alpha-numeric and underscore characters',
//   'string.pattern.base': '{{#label}} with value {:[.]} fails to match the required pattern: {{#regex}}',
//   'string.pattern.name': '{{#label}} with value {:[.]} fails to match the {{#name}} pattern',
//   'string.pattern.invert.base': '{{#label}} with value {:[.]} matches the inverted pattern: {{#regex}}',
//   'string.pattern.invert.name': '{{#label}} with value {:[.]} matches the inverted {{#name}} pattern',
//   'string.trim': '{{#label}} must not have leading or trailing whitespace',
//   'string.uri': '{{#label}} must be a valid uri',
//   'string.uriCustomScheme': '{{#label}} must be a valid uri with a scheme matching the {{#scheme}} pattern',
//   'string.uriRelativeOnly': '{{#label}} must be a valid relative uri',
//   'string.uppercase': '{{#label}} must only contain uppercase characters'
// }

const SignUpPage = (props) => {
  const [apiResponse, setApiResponse] = useState(defaults.apiResponseDefault);
  const [isBusy, setIsBusy] = useState(false);
  const isProduction = appServices.utility.isProduction();

  if (isProduction) {
    window.location = Routes.login;
    return;
  }

  const signUp = async (data) => {
    setIsBusy(true);
    const response = await appServices.api.auth.signUp(data);
    setIsBusy(false);
    // console.log(response);

    if (!response.ok) {
      // console.log(ex);
      setApiResponse({
        ...apiResponse,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response.data),
      });

      if (response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        setApiResponse({ ...apiResponse, errors: response.data?.errors });
      }

      setIsBusy(false);
      return;
    }

    if (
      response.status === StatusCodes.OK ||
      response.status === StatusCodes.CREATED
    ) {
      toast.success("Your sing up was successful!");
      setApiResponse({ ...defaults.apiResponseDefault, isSuccess: true });
      window.location = Routes.dashboard;
    }
  };

  return (
    <section className="body-sign body-sign-signup">
      <div className="center-sign">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/nss-logo.svg`}
          height="54"
          alt="Portal logo"
          className="logo float-start"
        />
        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>{" "}
              Register
            </h2>
          </div>
          <div className="card-body">
            <UserForm
              inputSize={ElementSize.lg}
              isBusy={isBusy}
              apiResponse={apiResponse}
              submitButtonText="Register"
              withNotes={false}
              onSubmit={async (data) => await signUp(data)}
            />
            <>
              Already have an account? Please{" "}
              <Link to={Routes.login}>log in</Link>.
            </>
          </div>
        </div>

        <CopyrightFooter />
      </div>
    </section>
  );
};

export default SignUpPage;
