import React, { useEffect, useRef, useState } from "react";

import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NodePositions } from "../../constants/misc";
import useImportCss from "../../hooks/userImportCss";
import useImportScript from "../../hooks/useImportScript";

const ImagePicker = ({
  initialImage,
  thumbnailTitle,
  maxFileSize = process.env.REACT_APP_MAX_UPLOAD_SIZE,
  validFormats = process.env.REACT_APP_VALID_LOGO_TYPES,
  onFileSelectionChanged,
  ...props
}) => {
  const fileInput = useRef(null);
  const [maxSizeExceeded, setMaxSizeExceeded] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);

  const [imagePreview, setImagePreview] = useState(
    initialImage ??
      `${process.env.PUBLIC_URL}/assets/img/camera-placeholder.jpg`
  );

  useImportCss(
    `${process.env.PUBLIC_URL}/theme/vendor/bootstrap-fileupload/bootstrap-fileupload.min.css`,
    true,
    NodePositions.Prepend
  );
  useImportCss(
    `${process.env.PUBLIC_URL}/assets/css/file-upload.css`,
    true,
    NodePositions.Append
  );
  useImportScript(
    `${process.env.PUBLIC_URL}/theme/vendor/bootstrap-fileupload/bootstrap-fileupload.min.js`
  );

  useEffect(() => {
    setImagePreview(
      initialImage ??
        `${process.env.PUBLIC_URL}/assets/img/camera-placeholder.jpg`
    );
    return () => {};
  }, [initialImage]);

  const handleFileSelected = (file) => {
    // console.log(file);
    const previewSrc = file
      ? URL.createObjectURL(file)
      : `${process.env.PUBLIC_URL}/assets/img/camera-placeholder.jpg`;
    setImagePreview(previewSrc);

    setInvalidFormat(file && !validFormats.includes(file?.type));
    setMaxSizeExceeded(file && file?.size > maxFileSize);

    if (onFileSelectionChanged) onFileSelectionChanged(file);
  };

  return (
    <>
      <div className="thumb-info mb-3">
        <img
          src={imagePreview}
          className="rounded img-fluid"
          alt="Company Logo"
        />
        {thumbnailTitle && (
          <div className="thumb-info-title">
            <span className="thumb-info-inner font-weight-normal text-lowercase">
              {thumbnailTitle}
            </span>
            {/* <span className="thumb-info-type">
                  {userModel.getMetaData(MetaDataKeys.AdminNotes) ??
                    userContext.currentUser?.CompanyName}
                </span> */}
          </div>
        )}
      </div>
      <div className="fileupload fileupload-new" data-provides="fileupload">
        <Form.Label>Upload Logo</Form.Label>
        <div className="input-append">
          <div className="uneditable-input">
            <i className="fas fa-file fileupload-exists"></i>
            <span className="fileupload-preview"></span>
          </div>
          <span className="btn btn-default btn-file me-2">
            <span className="fileupload-exists">Change</span>
            <span className="fileupload-new">Select file</span>
            <input
              type="file"
              ref={fileInput}
              onChange={() => handleFileSelected(fileInput?.current?.files[0])}
            />
          </span>
          <Link
            to="#"
            className="fileupload-exists"
            data-dismiss="fileupload"
            onClick={() => handleFileSelected(fileInput?.current?.files[0])}>
            Undo
          </Link>
        </div>
        <div>
          <small>
            <span className={invalidFormat ? "text-danger" : null}>
              Allowed formats: <strong>.png</strong> and <strong>.jpg</strong>
            </span>
          </small>
        </div>
        <div>
          <small>
            <span className={maxSizeExceeded ? "text-danger" : null}>
              Max size: <strong>{parseInt(maxFileSize / 1000000)} MB</strong>
            </span>
          </small>
        </div>
      </div>
    </>
  );
};

export default ImagePicker;
