import React, { useContext, useState } from "react";

import { ElementStyle } from "../constants/misc";
import ImagePicker from "./forms/ImagePicker";
import LoadingButton from "./buttons/LoadingButton";
import { StatusCodes } from "http-status-codes";
import { StorageProps } from "../constants/storageProps";
import { UserContext } from "../context/ContextProvider";
import appServices from "../services/appServices";
import { toast } from "react-toastify";

const ChangeUserLogo = ({ user, onSuccess, onError, ...props }) => {
  const [logo, setLogo] = useState(null);
  const [currentLogoUrl, setCurrentLogoUrl] = useState(user?.logo?.public_url);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [isDeletingLogo, setIsDeletingLogo] = useState(false);
  const userContext = useContext(UserContext);

  const handleFileSelectionChanged = (file) => {
    setLogo(file);

    if (file) {
      setCurrentLogoUrl(URL.createObjectURL(file));
    } else {
      setCurrentLogoUrl(user?.logo?.public_url);
    }
  };

  const handleUpload = async (event) => {
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("type", "logo");
    formData.append("file", logo);

    //-----------
    setIsUploadingLogo(true);
    // Perform the upload
    const response = await appServices.api.files.uploadLogo(formData);
    setIsUploadingLogo(false);

    if (!response.ok) return toast.error("Oops! Could not save the logo.");

    if (response?.status === StatusCodes.CREATED) {
      // if i am modifying the logo for the current user, update the current user object
      if (user?.id === userContext.currentUser?.id) {
        appServices.sessionStorage.setProperty(StorageProps.currentUser, {
          ...user,
          logo: response.data,
        });
        userContext.onCurrentUserChanged(
          appServices.sessionStorage.getProperty(StorageProps.currentUser)
        );
      }

      setCurrentLogoUrl(response.data?.public_url);

      toast.success("Logo saved!");
      if (onSuccess) onSuccess(response.data);
    }
  };

  const handleDeleteLogo = async () => {
    setIsDeletingLogo(true);
    //-----------
    // Perform the delete
    const response = await appServices.api.files.delete(user?.logo?.id);
    if (response.status === StatusCodes.NO_CONTENT) {
      // if i am deleting the logo for the current user, update the current user object
      if (user?.id === userContext.currentUser?.id) {
        appServices.sessionStorage.setProperty(StorageProps.currentUser, {
          ...user,
          logo: null,
        });
        userContext.onCurrentUserChanged(
          appServices.sessionStorage.getProperty(StorageProps.currentUser)
        );
      }
      setCurrentLogoUrl(null);

      toast.success("Logo deleted!");

      if (onSuccess) onSuccess(null);
    }
    //-----------
    setIsDeletingLogo(false);
  };

  return (
    <>
      <ImagePicker
        initialImage={currentLogoUrl}
        onFileSelectionChanged={(file) => handleFileSelectionChanged(file)}
      />
      <div className="row mt-2">
        <div className="col">
          <LoadingButton
            className="btn-block"
            isDisabled={!logo}
            isLoading={isUploadingLogo}
            onClick={handleUpload}>
            Upload Logo
          </LoadingButton>
        </div>
        <div className="col">
          <LoadingButton
            className="btn-block"
            elementStyle={ElementStyle.Danger}
            isDisabled={!user?.logo}
            isLoading={isDeletingLogo}
            onClick={handleDeleteLogo}>
            Delete Logo
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default ChangeUserLogo;
