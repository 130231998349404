import { Link } from "react-router-dom";
import React from "react";

const StyledDropDownButton = ({
  actions,
  className,
  elementStyle,
  hideCaret,
  iconClass = "",
  ...props
}) => {
  let buttonClassName = className ?? `mb-1 mt-1 mr-1 btn dropdown-toggle`;
  if (hideCaret) buttonClassName = `${buttonClassName} no-caret`;
  if (elementStyle) buttonClassName = `${buttonClassName} btn-${elementStyle}`;

  return (
    <div className="btn-group flex-wrap">
      <button
        type="button"
        className={buttonClassName}
        data-bs-toggle="dropdown">
        <i className={iconClass}></i> {!hideCaret && <span className="caret" />}
      </button>
      <div className="dropdown-menu" role="menu">
        {actions?.map((action) => (
          <Link
            key={JSON.stringify(action)}
            to={action.to ? action.to : "#"}
            className={`dropdown-item text-1 ${action.className}`}
            onClick={action.onClick}>
            {action.icon && <i className={`${action.icon} me-2`} />}
            {action.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default StyledDropDownButton;
