import { StatusCodes } from "http-status-codes";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoginForm from "../../components/forms/LoginForm";
import { ElementSize } from "../../constants/misc";
import { StorageProps } from "../../constants/storageProps";
import appServices from "../../services/appServices";

const LoginPage = (props) => {
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const unauthorized =
    queryParams.get("status") === StatusCodes.UNAUTHORIZED.toString();
  const mobileView = queryParams.get("target") === "mobile-app";

  const sessionMobileView = appServices.sessionStorage.getProperty(
    StorageProps.mobileView
  );
  appServices.sessionStorage.setProperty(
    StorageProps.mobileView,
    mobileView || sessionMobileView
  );

  if (unauthorized) toast.error("Your session expired.", { theme: "colored" });

  useEffect(() => {
    // const sessionMobileView = appServices.sessionStorage.getProperty(
    //   StorageProps.mobileView
    // );
    // appServices.sessionStorage.setProperty(
    //   StorageProps.mobileView,
    //   mobileView || sessionMobileView
    // );
  }, []);

  return <LoginForm isModal={false} inputSize={ElementSize.lg} />;
};

export default LoginPage;
