import ChangeUserLogo from "../ChangeUserLogo";
import { Modal } from "react-bootstrap";
import React from "react";
import StyledCard from "../styled/StyledCard";

const ChangeLogoDialog = ({
  user,
  title = "Change User Logo",
  show,
  onHide,
  onSuccess,
  ...props
}) => {
  const handleSuccess = (logo) => {
    if (onHide) onHide();
    if (onSuccess) onSuccess(logo);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="width-350"
      centered
      show={show}
      onHide={onHide}>
      <StyledCard title={title} showCloseButton onClose={onHide}>
        {user?.name}
        <ChangeUserLogo user={user} onSuccess={(logo) => handleSuccess(logo)} />
      </StyledCard>
    </Modal>
  );
};

export default ChangeLogoDialog;
