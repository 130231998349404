import * as Yup from "yup";

import { ElementSize, ElementStyle } from "../../constants/misc";
import React, { useState } from "react";

import AppForm from "../../components/forms/Form";
import CopyrightFooter from "../../components/CopyrightFooter";
import { Form } from "react-bootstrap";
import FormTextField from "../../components/forms/FormTextField";
import StyledAlert from "../../components/styled/StyledAlert";
import SubmittButton from "../../components/forms/SubmittButton";
import appServices from "../../services/appServices";
import { useHistory } from "react-router";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().min(1).required().label("Email"),
});

const ForgotPasswordPage = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    successMessage: null,
    errorMessage: null,
  });
  const history = useHistory();

  const handleCancel = (event) => {
    history.goBack();
  };

  const handleSubmit = async ({ email }) => {
    setIsBusy(true);
    const response = await appServices.api.passwords.forgotPassword(email);
    setIsBusy(false);

    if (!response.ok)
      return setApiResponse({
        successMessage: null,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

    setApiResponse({
      successMessage: response?.data?.message,
      errorMessage: null,
    });
  };

  return (
    <section className="body-sign">
      <div className="center-sign">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/nss-logo.svg`}
          height="54"
          alt="Portal logo"
          className="logo float-start"
        />
        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>{" "}
              Forgot Password
            </h2>
          </div>
          <div className="card-body">
            {/* If there is no successMessage, display the form */}
            {!apiResponse.successMessage ? (
              <>
                <StyledAlert
                  elementStyle={ElementStyle.Default}
                  style={{ padding: ".5rem" }}>
                  Please enter your <strong>email</strong>. We will send you a
                  reset link.
                </StyledAlert>
                <AppForm
                  initialValues={{ email: "" }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}>
                  <Form.Group className="form-group mb-3">
                    <FormTextField
                      name="email"
                      label="Email"
                      icon="bx bx-at text-4"
                      required
                      size={ElementSize.lg}
                    />
                  </Form.Group>

                  <div className="row">
                    <div className={`col-sm-12 text-end`}>
                      <button
                        type="button"
                        className="btn btn-default modal-dismiss m-2"
                        onClick={handleCancel}>
                        Cancel
                      </button>
                      <SubmittButton
                        title="Send Reset Link"
                        isLoading={isBusy}
                      />
                    </div>
                  </div>

                  {apiResponse.errorMessage && (
                    <p className="text-danger">{apiResponse.errorMessage}</p>
                  )}
                </AppForm>
              </>
            ) : (
              // Otherwise display the successMessage and nothing else
              <>
                <StyledAlert
                  elementStyle={ElementStyle.Success}
                  style={{ padding: ".5rem" }}>
                  {apiResponse.successMessage}
                </StyledAlert>
              </>
            )}
          </div>
        </div>

        <CopyrightFooter />
      </div>
    </section>
  );
};

export default ForgotPasswordPage;
