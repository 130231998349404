import React from "react";

const MaintenancePage = ({
  errorClassName = "inside",
  title,
  ...otherProps
}) => {
  return (
    <section className={`body-error error-${errorClassName}`}>
      <div className="center-error">
        <div className="row">
          <div className="col">
            <div className="main-error text-center mb-3">
              {/* <FontAwesomeIcon icon="fa-solid fa-person-digging" /> */}
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/work-in-progress.svg`}
                style={{ maxWidth: 250 }}
                // height="35"
                alt="Portal"
              />
              <h2 className="error-code text-dark text-center font-weight-semibold m-0">
                {/* <i class="fas fa-network-wired"></i> */}
                {title}
              </h2>
              <p className="error-explanation text-center">
                Sorry, we're down for scheduled maintenance right now
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MaintenancePage;
