import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

const storeUserMetaData = async (userId, metaArray) => {
  const payload = {
    user_id: userId,
    meta: metaArray,
  };

  const response = await httpService.post(ApiRoutes.storeUserMetaData, payload);

  return response;
};

//------------------------------------------------
const metaDataApiService = {
  storeUserMetaData,
};

export default metaDataApiService;
//------------------------------------------------
