const apiResponseDefault = {
  isSuccess: null,
  successMessage: null,
  errorMessage: null,
  errors: {},
};

//------------------------------------------------
const defaults = {
  apiResponseDefault,
};
export default defaults;
//------------------------------------------------
