import Enumerable from "linq";
import NSDataTableBody from "./NSDataTableBody";
import NSDataTableHeader from "./NSDataTableHeader";
import NSDataTableSearch from "./NSDataTableSearch";
import React from "react";
import { Table } from "react-bootstrap";
import useImportCss from "../../hooks/userImportCss";

const NSDataTable = ({
  className,
  columns,
  data,
  dense,
  paginate,
  search,
  sortBy,
  sortDirection,
  onSort,
  onSearch,
  emptyDataComponent,
  conditionalRowStyles,
  ...props
}) => {
  // Import styles
  useImportCss(
    `${process.env.PUBLIC_URL}/theme/vendor/datatables/media/css/dataTables.bootstrap5.css`
  );
  useImportCss(`${process.env.PUBLIC_URL}/assets/css/data-tables.css`);

  const headers = Enumerable.from(columns)
    .select((x) => x.header)
    .toArray();
  const cells = Enumerable.from(columns)
    .select((x) => x.cell)
    .toArray();

  const tableClassNames = `${className} ${dense ? "dense" : ""}`.trim();

  return (
    <>
      {search && <NSDataTableSearch onSearch={onSearch} />}
      <Table className={tableClassNames} {...props} striped hover>
        <NSDataTableHeader
          headers={headers}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <NSDataTableBody
          rows={data}
          columns={columns}
          cells={cells}
          conditionalRowStyles={conditionalRowStyles}
          emptyDataComponent={emptyDataComponent}
        />
      </Table>
    </>
  );
};

export default NSDataTable;
