import React, { useEffect, useState } from "react";

import Breadcrumbs from "../components/Breadcrumbs";
import CustomSpinner from "../components/CustomSpinner";
import PageHeaderV2 from "../components/PageHeaderV2";
import LoadingButton from "../components/buttons/LoadingButton";
import StyledCard from "../components/styled/StyledCard";

const TestPage = () => {
  const [isBusy] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  const handleClick = async () => {};

  return (
    <>
      <PageHeaderV2 title="Test Page">
        <Breadcrumbs>
          <ol className="breadcrumbs"></ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <CustomSpinner isActive={false} />

      <div className="row">
        <div className="col-md-5">
          <StyledCard>
            <LoadingButton
              className="btn btn-default modal-dismiss mt-2 me-2"
              onClick={handleClick}
              isLoading={isBusy}
            >
              Fetch Reports
            </LoadingButton>
          </StyledCard>
        </div>
      </div>
    </>
  );
};

export default TestPage;
