class LDUser {
  constructor() {
    this.user_id = null;
    this.username = null;
    this.email = null;
    this.name = null;
    this.access_token = null;
    this.refresh_token = null;
    this.client_name = null;
  }
}

export default LDUser;
