import { NodePositions, ReportTypes } from "../constants/misc";

import Enumerable from "linq";

//------------------------------------------------
const utilityService = {
  isProduction,
  appendScript,
  removeScript,
  appendStyleSheet,
  removeStyleSheet,
  getReportIcon,
  getReportImage,
  biReportsToTreeViewData,
  clearCache,
  sanitizePaginationUrl,
};

export default utilityService;
//------------------------------------------------

function isProduction() {
  return process.env.REACT_APP_ENV === "prod";
}

function appendScript(scriptToAppend, position = NodePositions.Append) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = scriptToAppend;
  // script.async = true;
  if (position === NodePositions.Prepend) {
    const rootNode = document.getElementById("root");
    rootNode.after(script);
  } else document.body.appendChild(script);

  return script;
}

function removeScript(scriptToRemove) {
  document.body.removeChild(scriptToRemove);
}

function appendStyleSheet(cssToAppend, position = NodePositions.Append) {
  const css = document.createElement("link");
  css.type = "text/css";
  css.rel = "stylesheet";
  css.href = cssToAppend;
  // script.async = true;
  if (position === NodePositions.Prepend) {
    const firstLinkNode = document.querySelector("[type='text/css']");
    document.head.insertBefore(css, firstLinkNode);
    // document.head.insertAdjacentElement("afterbegin", css);
    // document.head.insertAdjacentElement("beforebegin", css);
  } else document.head.appendChild(css);

  return css;
}

function removeStyleSheet(cssToRemove) {
  document.head.removeChild(cssToRemove);
}

/**
 * Returns the FontAwesome icon for the given report type
 * @param {string} reportType
 * @returns
 */
function getReportIcon(reportType, defaultIcon = "fas fa-chart-area") {
  let reportIconClass = null;

  switch (reportType) {
    case ReportTypes.Dashboard:
      reportIconClass = "fas fa-tachometer-alt";
      break;

    case ReportTypes.Advanced:
      reportIconClass = "fas fa-chart-pie";
      break;

    case ReportTypes.ExpressView:
      reportIconClass = "fas fa-chart-line";
      break;

    case ReportTypes.Chained:
      reportIconClass = "fas fa-link";
      break;

    case ReportTypes.CrossTab:
      reportIconClass = "far fa-chart-bar";
      break;

    default:
      reportIconClass = defaultIcon;
      break;
  }

  return reportIconClass;
}

/**
 * Returns the FontAwesome icon for the given report type
 * @param {string} reportType
 * @returns
 */
function getReportImage(reportType) {
  // console.log(reportType);
  let reportImagePath = null;

  switch (reportType) {
    case ReportTypes.Dashboard:
      // Buy here: https://www.iconfinder.com/icons/2248876/chart_diagram_graph_analysis_analytics_report_statistics_icon
      reportImagePath =
        reportImagePath = `${process.env.PUBLIC_URL}/assets/img/dashboard-icon.svg`;
      break;

    default:
      reportImagePath = `${process.env.PUBLIC_URL}/assets/img/report-icon.svg`;
      break;
  }

  return reportImagePath;
}

function biReportsToTreeViewData(
  reports,
  userReports = null,
  isCollapsed = true
) {
  const nodes = Enumerable.from(reports)
    .select((x) => {
      const isReadonly = Enumerable.from(userReports).firstOrDefault(
        (y) => y.biReportId === x.id
      )?.isReadOnly;

      const isSelected = Enumerable.from(userReports).any(
        (y) => y.biReportId === x.id
      );

      const node = {
        id: x.id,
        text: `${x.name}${isReadonly ? " (managed by TS)" : ""}`,
        icon: getReportIcon(x.type, "fas fa-folder"),
        type: x.type,
        state: {
          opened: !isCollapsed,
          disabled: isReadonly,
          selected: isSelected,
        },
        isLeaf: x.isLeaf,
        li_attr: {
          class: x.isLeaf ? "colored-icon-secondary" : "folder",
        },
        children: biReportsToTreeViewData(x.children, userReports, isCollapsed),
      };

      if (x.description) {
        node.a_attr = { title: x.description };
      }

      return node;
    })
    .toArray();

  return nodes;
}

function clearCache() {
  // window.history.forward(1);
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
}

function sanitizePaginationUrl(url) {
  if (url === null) return null;
  return new URL(url).search;
}
