import { Link } from "react-router-dom";
import React from "react";

const Card = ({
  children,
  className,
  elementStyle,
  featured,
  onClick,
  ...otherProps
}) => {
  className = className ? `card ${className}` : "card";

  if (onClick) className = `${className} cur-pointer`;
  if (featured) className = `${className} card-featured`;
  if (elementStyle) className = `${className} card-featured-${elementStyle}`;

  return (
    <section className={className} onClick={onClick} {...otherProps}>
      {children}
    </section>
  );
};

const Title = ({ children }) => <h2 className="card-title">{children}</h2>;

const Header = ({ children, collapsible }) => (
  <header className="card-header">
    {!!collapsible && (
      <div class="card-actions">
        <Link
          to="#"
          class="card-action card-action-toggle"
          data-card-toggle=""></Link>
      </div>
    )}
    {children}
  </header>
);
Header.Title = Title;
Card.Header = Header;

const Body = ({ children }) => <div className="card-body">{children}</div>;
Card.Body = Body;

const Footer = ({ children }) => <div className="card-footer">{children}</div>;
Card.Footer = Footer;

export default Card;
