class File {
  constructor() {
    this.id = null;
    this.user_id = null;
    this.file_name = null;
    this.public_url = null;
    this.thumbnail_url = null;
  }
}

export default File;
