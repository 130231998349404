import React from "react";
import { debounce } from "lodash";

const NSDataTableSearch = ({ onSearch, ...props }) => {
  const handleChangeWithDebounce = debounce(
    (event) => onSearch(event.target.value),
    500
  );

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6"></div>
      <div className="col-sm-12 col-md-6">
        <div className="dataTables_filter">
          <label>
            <input
              type="search"
              className="form-control pull-right"
              placeholder="Search..."
              onChange={handleChangeWithDebounce}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default NSDataTableSearch;
