import { ApiRoutes } from "../../constants/apiRoutes";
import httpService from "../httpService";

const attachProjectToAccount = async (payload) => {
  const url = ApiRoutes.dundasAttachProjectToAccount;
  const response = await httpService.post(url, payload);

  return response;
};

const createAccount = async (payload) => {
  const url = ApiRoutes.dundasCreateAccount;
  const response = await httpService.post(url, payload);

  return response;
};

const deleteAccount = async (userId, deleteFolders) => {
  let url = ApiRoutes.dundasDeleteAccount.replace("{userId}", userId);
  if (!!deleteFolders) url = `${url}?deleteFolders=true`;

  const response = await httpService.delete(url);

  return response;
};

const getAccount = async (id) => {
  const url = ApiRoutes.dundasGetAccount.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getAccountByName = async (name) => {
  const url = ApiRoutes.dundasGetAccountByName.replace("{name}", name);
  const response = await httpService.get(url);

  return response;
};

const getAllProjects = async () => {
  const url = ApiRoutes.dundasGetAllProjects;
  const response = await httpService.get(url);

  return response;
};

const getLogOnToken = async () => {
  const url = ApiRoutes.dundasGetLogOnToken;
  const response = await httpService.get(url);

  return response;
};

const getProjectItems = async (name) => {
  const url = ApiRoutes.dundasGetProjectItems;
  const response = await httpService.get(url);

  return response;
};

const getUsersProjectItems = async (urlParams) => {
  const url = `${ApiRoutes.dundasGetUsersProjectItems}${urlParams}`;
  const response = await httpService.get(url);

  return response;
};

//------------------------------------------------
const dundasApiService = {
  attachProjectToAccount,
  deleteAccount,
  getAccount,
  getAccountByName,
  getAllProjects,
  getLogOnToken,
  getProjectItems,
  getUsersProjectItems,
  createAccount,
};

export default dundasApiService;
//------------------------------------------------
