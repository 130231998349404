import * as Yup from "yup";

import React, { useState } from "react";

import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CopyrightFooter from "../../components/CopyrightFooter";
import AppForm from "../../components/forms/Form";
import FormTextField from "../../components/forms/FormTextField";
import SubmittButton from "../../components/forms/SubmittButton";
import { ElementSize } from "../../constants/misc";
import { Routes } from "../../constants/routes";
import appServices from "../../services/appServices";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().min(1).required().label("Email"),
  password: Yup.string()
    .min(6, "Password is too short (min 6)")
    .max(15, "Password is too long (max 15)")
    .required()
    .label("Password"),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .label("Confirm password"),
});

const ResetPasswordPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const token = query.get("token");

  const [apiResponse, setApiResponse] = useState({
    successMessage: null,
    errorMessage: null,
  });
  const [isBusy, setIsBusy] = useState(false);

  const history = useHistory();

  const handleSubmit = async ({ email, password, password_confirmation }) => {
    setIsBusy(true);
    const response = await appServices.api.passwords.resetPassword(
      token,
      email,
      password,
      password_confirmation
    );
    setIsBusy(false);

    if (!response.ok)
      return setApiResponse({
        successMessage: null,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

    toast.success(response?.data?.message);
    history.push(Routes.login);
  };

  return (
    <section className="body-sign">
      <div className="center-sign">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/nss-logo.svg`}
          height="54"
          alt="Portal logo"
          className="logo float-start"
        />
        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>{" "}
              Reset Password
            </h2>
          </div>
          <div className="card-body">
            <AppForm
              initialValues={{
                email: "",
                password: "",
                password_confirmation: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {/* Email */}
              <Form.Group className="form-group mb-3">
                <FormTextField
                  name="email"
                  label="Email"
                  icon="bx bx-at text-4"
                  required
                  size={ElementSize.lg}
                />

                {/* Password */}
                <FormTextField
                  icon="bx bx-lock text-4"
                  label="Password"
                  name="password"
                  required
                  size={ElementSize.lg}
                  type="password"
                />

                {/* Confirm password */}
                <FormTextField
                  icon="bx bx-lock text-4"
                  label="Confirm Password"
                  name="password_confirmation"
                  required
                  size={ElementSize.lg}
                  type="password"
                />
              </Form.Group>

              <div className="row">
                <div className={`col-sm-12 text-end`}>
                  <Link to={Routes.login} className="btn btn-default m-2">
                    Cancel
                  </Link>
                  <SubmittButton title="Reset Password" isLoading={isBusy} />
                </div>
              </div>

              {apiResponse.errorMessage && (
                <p className="text-danger">{apiResponse.errorMessage}</p>
              )}
            </AppForm>
          </div>
        </div>

        <CopyrightFooter />
      </div>
    </section>
  );
};

export default ResetPasswordPage;
