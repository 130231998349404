import LoadingButton from "../buttons/LoadingButton";
import React from "react";
import { useFormikContext } from "formik";

const SubmittButton = ({
  isLoading,
  children: title = "Submit",
  ...otherProps 
}) => {
  const { handleSubmit } = useFormikContext();

  return (
    <LoadingButton
      type="submit"
      isLoading={isLoading} 
      onClick={handleSubmit}
      {...otherProps}
    >
      {title}
    </LoadingButton>
  );
};

export default SubmittButton;
