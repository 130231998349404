import { useField, useFormikContext } from "formik";

import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import { ElementStyle } from "../../constants/misc";
import FormFieldContainer from "../FormFieldContainer";
import React from "react";
import StyledSwitch from "../styled/StyledSwitch";

const FormToggleField = ({
  containerClassName,
  description,
  disabled = false,
  elementStyle = ElementStyle.Primary,
  info,
  label = null,
  LabelComponent,
  labelClassName,
  onChange = () => {},
  placeholder,
  required,
  toggleClassName,
  visible = true,
  ...otherProps
}) => {
  const [field] = useField(otherProps);
  const formik = useFormikContext();
  const { errors, handleChange, setFieldValue, touched, values } = formik;

  const handleCheckChange = (e) => {
    setFieldValue(field.name, e.target.checked);
    onChange(e.target.checked, formik);
  };

  if (!visible) return null;

  return (
    <FormFieldContainer className={containerClassName}>
      <AppFormLabel
        label={label}
        required={required}
        className={labelClassName}
        visible={!!label}
      />
      {LabelComponent}
      <div>
        <StyledSwitch
          className={toggleClassName}
          elementStyle={elementStyle}
          isChecked={!!field.value ?? false}
          name={field.name}
          onChange={handleCheckChange}
        />
        <AppErrorMessage
          error={errors[field.name]}
          visible={touched[field.name] && errors[field.name]}
        />
      </div>
    </FormFieldContainer>
  );
};

export default FormToggleField;
