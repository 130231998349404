import Breadcrumbs from "../../components/Breadcrumbs";
import PageHeaderV2 from "../../components/PageHeaderV2";
import React from "react";

const DeveloperResourcesPage = () => {
  return (
    <>
      <PageHeaderV2 title="Developer Resources">
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs"></ol>
        </Breadcrumbs>
      </PageHeaderV2>
    </>
  );
};

export default DeveloperResourcesPage;
