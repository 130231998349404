import { useHistory } from "react-router-dom";

import React, { useState } from "react";
import { toast } from "react-toastify";
import appServices from "../services/appServices";
import ReportPreviewCard from "./ReportPreviewCard";
import { Routes } from "../constants/routes.js";

const ReportPreviewCards = ({ dundasDashboards, ...otherProps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getDundasTokenAsync = async () => {
    try {
      setIsLoading(true);
      const response = await appServices.api.dundas.getLogOnToken();
      // console.log("LogOn Token", response);

      if (!response.ok) return;

      return response.data.logOnToken;
    } finally {
      setIsLoading(false);
    }
  };

  const handleDundasReportClick = async (dashboard) => {
    if (dashboard.initialViewOptions !== "viewonly")
      return toast.warning(
        `Please contact North Star Systems to view this ${dashboard.type.toLowerCase()}`
      );

    const dundasLogOnToken = await getDundasTokenAsync();
    if (!dundasLogOnToken)
      return toast.error("Oops! Could not fetch the dashboard.");

    // all good, open the dashboard
    // window.open(
    //   `${process.env.REACT_APP_DUNDAS_URL}/${dashboard.type}/${dashboard.id}?logonTokenId=${dundasLogOnToken}`,
    //   "_blank",
    //   "fullscreen=yes"
    // );

    history.push({
      pathname: Routes.report_details.replace(":id", dashboard.id),
      state: {
        name: dashboard.name,
        source: "dundas",
        type: dashboard.type,
      },
    });
  };

  return (
    <div className="d-flex flex-row flex-wrap gap-3 mb-3 nsbi-report-card-container">
      {dundasDashboards?.map((dashboard, index) => (
        <ReportPreviewCard
          key={`dashboard-${index}`}
          className="nsbi-report-card cur-pointer"
          onClick={handleDundasReportClick}
          report={dashboard}
          loading={isLoading}
        />
      ))}
    </div>
  );
};

export default ReportPreviewCards;
