import React from "react";

const Breadcrumbs = (props) => {
  return (
    <div className="right-wrapper text-end">
      {props.children}
      {/* <Link to="#" className="sidebar-right-toggle" data-open="sidebar-right">
        <i className="fas fa-chevron-left"></i>
      </Link> */}
    </div>
  );
};

export default Breadcrumbs;
