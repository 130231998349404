import React from "react";

const EmptyDataBox = ({
  text = "There are no records to display.",
  ...props
}) => {
  return (
    <div className={`empty-result-info ${props.className ?? ""}`}>
      <span>{text}</span>
    </div>
  );
};

export default EmptyDataBox;
